import React, { useContext, useEffect, useRef, useState } from "react";
import { UserInfo } from "../../common/UserInfo";
import { AppContext } from "../../AppContext";
import Header from "../controls/Header";
import ContentView from "../ContentView";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "1rem",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "2rem",
    },
  },
  mainContainer: {
    paddingBottom: 10,
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: `calc(100vw - 2rem)`,
    },
    [theme.breakpoints.up("xl")]: {
      width: `calc(100vw - 4rem)`,
    },

    "& > .scrollbar-container": {
      padding: "20px 20px 0",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        padding: "30px 32px 0",
      },
    },
  },
}));

type AppLayoutProps = {
  children: any;
};

const AppLayout: React.FunctionComponent<AppLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const appContext = useRef(useContext(AppContext));
  const [userInfo, setUserInfo] = useState<UserInfo>();
  useEffect(() => {
    (async () => {
      try {
        let user = await appContext.current.imReportingUserStore.getUserInfo();
        console.log("GET or CHECK FOR USER", user);
        setUserInfo(user);
      } catch (error) {
        console.log("GET USER ERROR: ", error);
      }
    })();
  }, []);
  return (
    <>
      <Header userInfo={userInfo} />
      <Box className={classes.mainContent}>
        <Box className={classes.mainContainer}>
          <ContentView>{children}</ContentView>
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;
