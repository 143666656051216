import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AppLayout from "../../components/AppLayout";

type LoginProps = {};
const UnauthorizedPage: React.FunctionComponent<LoginProps> = () => {
  return (
    <AppLayout>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">

          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            You are not authorized to access this page.  Please contact your administrator if you feel you should have access.
          </Typography>

        </Container>
      </Box>
    </AppLayout>
  );
};

export default UnauthorizedPage;
