import { Grid, Theme } from "@mui/material";
import React, { useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { BankAccountInfo } from "../../../common/BankAccountInfo";
import { BankDeposit } from "../../../common/BankDeposit";
import { BillingGroup } from "../../../common/BillingGroup";
import { Currency } from "../../../common/Currency";
import { Invoice } from "../../../common/Invoice";
import { InvoicePayment } from "../../../common/InvoicePayment";
import { InvoiceType } from "../../../common/InvoiceType";
import RevenueAdjustmentInvoiceList from "../RevenueAdjustInvoiceList";
import RevenueAdjustSelection from "../RevenueAdjustSelection";
import { InvoiceAdjustment } from "../../../common/InvoiceAdjustment";
import { AppContext } from "../../../AppContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftPanel: {
      marginLeft: "-5px",
    },
    rightPanel: {
      marginLeft: "10px",
    },
    bottomRightPanel: {
      marginLeft: "10px",
      marginTop: "10px",
    },
  })
);

type RevenueAdjustmentInvoicesProps = {
    invoices: Invoice[];
    billingGroups: BillingGroup[];
    bankAccounts: BankAccountInfo[];
    invoiceTypes: InvoiceType[];
    deposits: BankDeposit[];
    currencies: Currency[];
    payments: InvoicePayment[];
    triggerRefresh: any;
    onSetSpinner:any;
};
const RevenueAdjustmentInvoices: React.FunctionComponent<
RevenueAdjustmentInvoicesProps
> = ({
    invoices,
    payments,
    currencies,
    billingGroups,
    bankAccounts,
    invoiceTypes,
    deposits,
    triggerRefresh,
    onSetSpinner
}) => {
    const classes = useStyles();
    const appContext = React.useContext(AppContext);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);


    const invoiceSelected = (invoice: Invoice) => {
      console.log('Selected Invoice', invoice);
      setSelectedInvoice(invoice);
    };


    const handlePostedAdjustment = (adjustment: InvoiceAdjustment) => {
      onSetSpinner(true);
      appContext.imReportingStore.postInvoiceAdjustment(adjustment).then((result) => {
        setSelectedInvoice(null);
        triggerRefresh();

        onSetSpinner(false);
      }).catch((error) => {
        console.log("Error from posting an adjustment", error);
        onSetSpinner(false);
      })
    }

  return (
    <>
        <Grid container>
        <Grid item xs={4} className={classes.leftPanel}>
          <RevenueAdjustmentInvoiceList 
            invoices={invoices}
            billingGroups={billingGroups}
            currencies={currencies}
            setSelectedInvoice={invoiceSelected}
          />
          </Grid>
          <Grid item xs={8}>
            <RevenueAdjustSelection 
              invoice={selectedInvoice}
              currencies={currencies}
              payments={payments}
              deposits={deposits}
              invoiceTypes = {invoiceTypes}
              billingGroups={billingGroups}
              bankAccounts={bankAccounts}
              postAdjustment = {handlePostedAdjustment}
            />
          </Grid>
        </Grid>
    </>
  );
};

export default RevenueAdjustmentInvoices;