import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import { BankDeposit } from "../../common/BankDeposit";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { Invoice } from "../../common/Invoice";
import { InvoicePayment } from "../../common/InvoicePayment";
import { InvoiceType } from "../../common/InvoiceType";
import {
  formatDate,
} from "../../util/Utils";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import StyledButton from "../controls/StyledButton";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MaterialTable from "@material-table/core";
import { materialTableIcons } from "../../common/TableIcons";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    rightButton: {
      marginLeft: "10px",
    },
    depoistsTable: {
      marginTop: "10px",
    },
  })
);

type InvoiceWithPaymentsProps = {
  billingGroups: BillingGroup[];
  bankAccounts: BankAccountInfo[];
  currencies: Currency[];
  invoiceTypes: InvoiceType[];
  invoice: Invoice | null | undefined;
  filteredPayments: InvoicePayment[];
  deposits: BankDeposit[];
  clearSelectedInvoice: any;
  unlinkPayment: any;
};

const InvoiceDepositMapping: React.FunctionComponent<InvoiceWithPaymentsProps> =
  ({ ...props }) => {
    const classes = useStyles();
    const [selectedDeposit, setSelectedDeposit] =
      useState<InvoicePayment | null>(null);

    const selectedPayment = (payment: InvoicePayment) => {
      setSelectedDeposit(payment);
    };

    const actions: any[] = [
      () => ({
        // eslint-disable-next-line react/jsx-no-undef
        icon: () => <DoneIcon />,
        tooltip: "Select",
        onClick: (event: any, row: InvoicePayment) => selectedPayment(row),
      }),
    ];
    return (
      <Grid
        container
        xs={12}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Selected Invoice
              </Typography>
              {props.invoice ? (
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <label>Invoice Number:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{props.invoice.InvoiceNumber}</label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Issue Date:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {formatDate(
                            props.invoice.IssueDate
                              ? props.invoice.IssueDate.toString()
                              : "",
                            false
                          )}
                        </label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Period Ending:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {formatDate(
                            props.invoice.PeriodEnding
                              ? props.invoice.PeriodEnding.toString()
                              : "",
                            false
                          )}
                        </label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Invoice Type:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {
                            props.invoiceTypes.find(
                              (bg) => bg.Id === props.invoice?.InvoiceTypeId
                            )?.Value
                          }
                        </label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Billing Group:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {
                            props.billingGroups.find(
                              (bg) => bg.Id === props.invoice?.GroupId
                            )?.GroupName
                          }
                        </label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Invoice Fee (USD):</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{props.invoice.FeeAmountUSD}</label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Adjustment (USD):</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{props.invoice.FeeAdjustmentUSD}</label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Deposits Amount Assigned (USD):</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {props.filteredPayments.reduce(
                            (sum, current) => sum + current.AmountPaid,
                            0
                          )}
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="left" alignItems="left">
                      <StyledButton
                        disabled={!selectedDeposit && !props.invoice}
                        label="Clear "
                        onClick={() => {
                          setSelectedDeposit(null);
                          props.clearSelectedInvoice();
                        }}
                      />

                      <div className={classes.rightButton}>
                        <StyledButton
                          label="Unlink Payment"
                          isSubmit={true}
                          disabled={
                            selectedDeposit === null ||
                            selectedDeposit === undefined ||
                            props.filteredPayments.length < 1
                          }
                          onClick={() => {
                            props.unlinkPayment(selectedDeposit);
                            setSelectedDeposit(null);
                            props.clearSelectedInvoice();
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={classes.depoistsTable}>
                    <MaterialTable
                      options={{
                        draggable: false,
                        thirdSortClick: false,
                        search: true,
                        sorting: true,
                        toolbar: true,
                        actionsColumnIndex: 0,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 25, 50],
                      }}
                      onSelectionChange={(rows) =>
                        console.log("selection change", rows)
                      }
                      localization={{
                        toolbar: { searchPlaceholder: "Search for deposit" },
                      }}
                      icons={materialTableIcons}
                      columns={[
                        {
                          title: "Bank Account",
                          field: "BankAccountId",
                          render: (rowData) => {
                            const bd = props.deposits.find(
                              (d) => d.Id === rowData.DepositId
                            );
                            return bd
                              ? props.bankAccounts.find(
                                  (b) => b.Id === bd?.BankAccountId
                                )?.Name
                              : "";
                          },
                        },

                        {
                          title: "Assigned to Invoice",
                          field: "AmountPaid",
                          render: (rowData) => rowData.AmountPaid,
                        },
                        {
                          title: "Notes",
                          field: "Notes",
                          render: (rowData) => <>{rowData.Notes}</>,
                        },
                        {
                          title: "Deposit Amount",
                          field: "DepositAmount",
                          render: (rowData) => {
                            return props.deposits.find(
                              (d) => d.Id === rowData.DepositId
                            )?.DepositAmount;
                          },
                        },
                        {
                          title: "Deposit Date",
                          render: (rowData) => {
                            const bd = props.deposits.find(
                              (d) => d.Id === rowData.DepositId
                            );
                            return formatDate(
                              bd?.DepositDate ? bd.DepositDate.toString() : "",
                              false
                            );
                          },
                        },
                        {
                          title: "Is Processed",
                          field: "IsProcesed",
                          render: (rowData) => {
                            return (
                              <>
                                <Checkbox
                                  checked={
                                    props.deposits.find(
                                      (d) => d.Id === rowData.DepositId
                                    )?.isProcessed
                                  }
                                />
                              </>
                            );
                          },
                        },

                      ]}
                      data={props.filteredPayments}
                      title="Select a deposit"
                      actions={actions}
                    ></MaterialTable>
                  </Grid>
                </Grid>
              ) : (
                <p>
                  <em>Please select an invoice.</em>
                </p>
              )}
            </CardContent>
            {props.invoice && (
              <CardActions disableSpacing>
                <IconButton aria-label="Clear selection">
                  <ClearIcon onClick={(e) => props.clearSelectedInvoice()} />
                </IconButton>
              </CardActions>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  };

export default InvoiceDepositMapping;
