import { BillingGroup } from "../../../common/BillingGroup";
import { Invoice } from "../../../common/Invoice";
import DoneIcon from '@mui/icons-material/Done';
import { Grid } from "@mui/material";
import MaterialTable from "@material-table/core";
import { materialTableIcons } from "../../../common/TableIcons";
import { Currency } from "../../../common/Currency";
import { formatAmount } from "../../../util/Utils";
type RevenueAdjustmentInvoiceListProps = {
    invoices: Invoice[];
    billingGroups: BillingGroup[];
    currencies: Currency[];
    setSelectedInvoice: any;
};
const RevenueAdjustmentInvoiceList: React.FunctionComponent<
RevenueAdjustmentInvoiceListProps
> = ({
    invoices,
    billingGroups,
    currencies,
    setSelectedInvoice
}) => {

    const actions: any [] = [
        () => ({
         // eslint-disable-next-line react/jsx-no-undef
         icon: () => <DoneIcon />,
         tooltip: "Edit",
         onClick: (event: any, row: Invoice) => setSelectedInvoice(row)
         
        }),
     ];

    return (
        <>
      <Grid container>
        <Grid item xs={12}>
        <MaterialTable
            options={{
              draggable: false,
              thirdSortClick: false,
              search: true,
              sorting: true,
              toolbar: true,
              actionsColumnIndex: 0,
              pageSize: 10,
              pageSizeOptions: [5, 10, 75, 100],
            }}
            onSelectionChange={(rows) => console.log("selection change", rows)}
            localization={{toolbar: {searchPlaceholder: 'Search invoices'}}}
            icons={materialTableIcons}
            columns={[
              {
                title: 'Invoice Number',
                field: 'InvoiceNumber',
                render: (rowData) => <>{rowData.InvoiceNumber}</>,
              },
              {
                title:'Amount',
                field: 'FeeAmountLocal',
                render: (rowData) => <>{formatAmount(rowData.FeeAmountLocal ?? 0, false)}</>                
              },
              {
                title:"Currency",
                field: "Currency",
                width: "3%",
                render: (rowData) => <>{currencies.find(c =>c.Id === rowData.CurrencyId)?.ISOCode} </>

              }

            ]}
            data={invoices}
            title="Select an Invoice"
            actions={actions}
          ></MaterialTable>
        </Grid>
      </Grid>
        </>
    )
}


export default RevenueAdjustmentInvoiceList;