import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AppContext } from "../../AppContext";
import { SnackbarParameters } from "../../common/SnackbarParameters";
import { UserInfo } from "../../common/UserInfo";
import AppLayout from "../../components/AppLayout";
import CloseIcon from "@mui/icons-material/Close";
import { Account } from "../../common/Account";
import PortalUserType from "../../common/PortalUserType";
import PortalRole from "../../common/PortalRole";
import { PortalUser } from "../../common/PortalUser";
import StyledButton from "../../components/controls/StyledButton";
import ConfirmationDialog, {
  ConfirmationDialogResult,
} from "../../components/ConfirmationDialog";
import PortalUserList from "../../components/PortalUsers/PortalUserList";
import PortalUserEntry from "../../components/PortalUsers/PortalUserEntry";
import TabPanel from "../../components/controls/TabPanel";
import PortalUserAccounts from "../../components/PortalUsers/PortalUserAccounts";
import { PortalUserAccountPermission } from "../../common/PortalUserAccountPermission";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type UserProps = { user: UserInfo | undefined };
const PortalUsers: React.FunctionComponent<UserProps> = ({ user }) => {
  const defaultUser: PortalUser = {
    Id: 0,
    Email: "", //FK, non null
    FirstName: "", //non null
    LastName: "",
    UserTypeId: 0, //FK non null
    UserRoleId: 0,
  };
  const appContext = React.useContext(AppContext);

  const defaultSnackbarParams: SnackbarParameters = {
    isOpen: false,
    message: "",
    alertStyle: "info",
  };
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState<Boolean>(false);
  const [isEditMode, setEditMode] = useState<Boolean>(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [portalUserTypes, setPortalUserTypes] = useState<PortalUserType[]>([]);
  const [portalRoles, setPortalRoles] = useState<PortalRole[]>([]);
  const [portalUserAccountPermissions, setPortalUserAccountPermissions] =
    useState<PortalUserAccountPermission[]>([]);
  const [selectedUser, setSelectedUser] = useState<PortalUser | undefined>(
    undefined
  );
  const [portalUsers, setPortalUsers] = useState<PortalUser[]>([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteDialogMessage, setDeleteDialogMessage] =
    React.useState<string>("");
  const [userToDelete, setUserToDelete] = React.useState<PortalUser | null>(
    null
  );
  const [snackbarParams, setSnackbarParams] = React.useState(
    defaultSnackbarParams
  );

  React.useEffect(
    () => {
      const fetchData = async () => {
        if (!isMetaDataLoaded) {
          try {
            setShowSpinner(true);

            let [
              accountsResp,
              portalUserTypesResp,
              portalRolesResp,
              portalusersResp,
              portalUserAccountsResp,
            ] = await Promise.all([
              appContext.imReportingStore.getAllAccounts(),
              appContext.imReportingStore.getAllPortalUserTypes(),
              appContext.imReportingStore.getAllPortalRoles(),
              appContext.imReportingStore.getAllPortalUsers(),
              appContext.imReportingStore.getAllPortalUserAccountPermissions(),
            ]);
            setAccounts(accountsResp);
            setPortalUserTypes(portalUserTypesResp);
            setPortalRoles(portalRolesResp);
            setPortalUsers(portalusersResp);
            setPortalUserAccountPermissions(portalUserAccountsResp);
            setIsMetaDataLoaded(true);
            setEditMode(false);
          } catch (fetchError) {
            setShowSpinner(false);
          }
          setShowSpinner(false);
        }
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMetaDataLoaded]
  );

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarParams(defaultSnackbarParams);
  };

  const handleSpinnerClose = () => {
    setShowSpinner(false);
  };

  const userSelectedForEdit = (user: PortalUser) => {
    setSelectedUser(user);
    setEditMode(true);
  };
  const cancelEdit = () => {
    setSelectedUser(defaultUser);
    setEditMode(false);
  };

  async function handleSaveUser(user: PortalUser) {
    setShowSpinner(true);
    appContext.imReportingStore
      .postPortalUsers(user)
      .then((result) => {
        setShowSpinner(false);
        setSelectedUser(defaultUser);
        setEditMode(false);
        setIsMetaDataLoaded(false);
      })
      .catch((error) => {
        console.log("Error saving user", error);
      });
  }

  const handleAccountsForSelectedUser = (user: PortalUser) => {
    setSelectedUser(user);
    setSelectedTab(1);
  }

  const handleDeleteUser = (user: PortalUser) => {
    console.log("Handle user deposit", user);
    setDeleteDialogMessage(
      `Delete the user ${user?.FirstName} ${user?.LastName}?`
    );
    setDeleteDialogOpen(true);
    setUserToDelete(user);
  };

  const handleDeleteDialogClose = (
    result: ConfirmationDialogResult,
    user: PortalUser
  ) => {
    setDeleteDialogOpen(false);
    if (result === ConfirmationDialogResult.Yes) {
      console.log("confirmed delete user", user);
      setShowSpinner(true);
      appContext.imReportingStore.deletePortalUser(user).then((result) => {
        setUserToDelete(null);
        setIsMetaDataLoaded(false);
      }).finally(() => {setShowSpinner(false);});
    }
  };

  const snackbarAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackbarClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSaveAccounts = (
    newAccounts: PortalUserAccountPermission[],
    deleteAccounts: PortalUserAccountPermission[]
  ) => {
    console.log("in save user accounts", newAccounts, deleteAccounts);
    setShowSpinner(true);
    appContext.imReportingStore
      .postPortalUserAccountPermissions(newAccounts, deleteAccounts)
      .then((result) => {
        setShowSpinner(false);
        setIsMetaDataLoaded(false);
      })
      .catch((error) => {
        console.log("Error saving account permissions", error);
      });
  };


  return (
    <>
      <AppLayout>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Manage Portal Users" {...a11yProps(0)} />
            <Tab
              label="Manage Portal User Accounts"
              {...a11yProps(1)}
              style={{ marginLeft: "10px" }}
            />
          </Tabs>
        </Box>

        <TabPanel value={selectedTab} index={0}>
          <Grid container>
            <Grid container alignItems="flex-start" alignContent="center">
              <Grid item xs={12}>
                <Typography variant="h5">Add and Edit Users</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              {isEditMode === true ? (
                <PortalUserEntry
                  portalUser={selectedUser}
                  portalUserTypes={portalUserTypes}
                  portalRoles={portalRoles}
                  accounts={accounts}
                  cancelEdit={cancelEdit}
                  saveUser={handleSaveUser}
                />
              ) : (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "30px",
                        paddingBottom: "10px",
                      }}
                    >
                      <StyledButton
                        label="Add User"
                        onClick={() => userSelectedForEdit(defaultUser)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PortalUserList
                        portalUsers={portalUsers}
                        portalUserTypes={portalUserTypes}
                        portalRoles={portalRoles}
                        accounts={accounts}
                        setSelectedUser={userSelectedForEdit}
                        deletePortalUser={handleDeleteUser}
                        accountsforSelectedUser={handleAccountsForSelectedUser}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <PortalUserAccounts
            portalUserAccounts={portalUserAccountPermissions}
            portalUser={selectedUser}
            allUsers={portalUsers}
            accounts={accounts}
            saveAccounts={handleSaveAccounts}
          />
        </TabPanel>

        <ConfirmationDialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          message={deleteDialogMessage}
          title="Delete Invoice"
          isYesNo={true}
          data={userToDelete}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showSpinner}
          onClick={handleSpinnerClose}
        >
          <CircularProgress color="inherit" size="5rem" thickness={1} />
        </Backdrop>
        <Snackbar
          open={snackbarParams.isOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          action={snackbarAction}
        >
          <Alert severity={snackbarParams.alertStyle}>
            {snackbarParams.message}
          </Alert>
        </Snackbar>
      </AppLayout>
    </>
  );
};

export default PortalUsers;
