import AppLayout from "../../components/AppLayout";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import { AppContext } from "../../AppContext";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { Invoice } from "../../common/Invoice";
import { InvoiceType } from "../../common/InvoiceType";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import { BankDeposit } from "../../common/BankDeposit";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../components/controls/TabPanel";
import LinkDepostsToInvoices from "../../components/LinkDeposits/LinkDepositsToInvoices";
import ManageLinkedDeposits from "../../components/LinkDeposits/ManagedLinkedDeposits";
import { InvoicePayment } from "../../common/InvoicePayment";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type LinkDepositsProps = {};
const LinkDeposits: React.FunctionComponent<LinkDepositsProps> = () => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState<Boolean>(false);
  const appContext = React.useContext(AppContext);
  const [billingGroups, setBillingGroups] = useState<BillingGroup[]>([]);
  const [invoiceTypes, setInvoiceTypes] = useState<InvoiceType[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [bankAccountInfos, setBankAccountInfos] = useState<BankAccountInfo[]>(
    []
  );
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [invoicePayements, setInvoicePayments] = useState<InvoicePayment[]>([]);

  const [deposits, setDeposits] = useState<BankDeposit[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isMetaDataLoaded) {
        try {
          setShowSpinner(true);
          let [
            billingGroupsResp,
            currenciesResp,
            invoiceTypesResp,
            invoicesResp,
            bankAccountInfosResp,
            depositsResp,
            paymentsResp,
          ] = await Promise.all([
            appContext.imReportingStore.getBillingGroups(),
            appContext.imReportingStore.getBillingCurrencies(),
            appContext.imReportingStore.getInvoiceTypes(),
            appContext.imReportingStore.getAllInvoices(false,true),
            appContext.imReportingStore.getAllBankAccountInfos(),
            appContext.imReportingStore.getAllBankDeposits(false, true),
            appContext.imReportingStore.getAllInvoicePayments(),
          ]);
          //Set state on the returned values.
          setBankAccountInfos(bankAccountInfosResp);
          setDeposits(depositsResp);
          setBillingGroups(billingGroupsResp);
          setInvoiceTypes(invoiceTypesResp);
          setCurrencies(currenciesResp);
          setInvoices(invoicesResp);
          setInvoicePayments(paymentsResp);
          setIsMetaDataLoaded(true);
        } catch (fetchError) {
          console.log(fetchError);
          setShowSpinner(false);
          // enqueueSnackbar(
          //   `Error retrieving metadata data for the page.`,
          //   { variant: "error" }
          // );
        }
        setShowSpinner(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetaDataLoaded]);

  const handleSpinnerClose = () => {
    setShowSpinner(false);
  };

  const handleSpinnerAction = (open: boolean) => {
    setShowSpinner(open);
  };

  const handleChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const triggerDataRefresh = () => {
    setIsMetaDataLoaded(false);
  };

  return (
    <>
      <AppLayout>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Link Deposits to Invoices" {...a11yProps(0)} />
            <Tab
              label="Manage Linked Deposits"
              {...a11yProps(1)}
              style={{ marginLeft: "10px" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          <LinkDepostsToInvoices
            invoices={invoices.filter(i => i.isClosed === false)}
            invoiceTypes={invoiceTypes}
            currencies={currencies}
            payments={invoicePayements}
            billingGroups={billingGroups}
            deposits={deposits.filter(d => d.isProcessed === false)}
            bankAccounts={bankAccountInfos}
            triggerRefresh={triggerDataRefresh}
            onSetSpinner={handleSpinnerAction}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <ManageLinkedDeposits
            invoices={invoices}
            invoiceTypes={invoiceTypes}
            currencies={currencies}
            payments={invoicePayements}
            billingGroups={billingGroups}
            deposits={deposits}
            bankAccounts={bankAccountInfos}
            triggerRefresh={triggerDataRefresh}
            onSetSpinner={handleSpinnerAction}
          />
        </TabPanel>
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
        onClick={handleSpinnerClose}
      >
        <CircularProgress color="inherit" size="5rem" thickness={1} />
      </Backdrop>
      </AppLayout>
    </>
  );
};

export default LinkDeposits;
