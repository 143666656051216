import {
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormLabel,
  Card,
  CardContent,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Invoice } from "../../common/Invoice";
import React, { Fragment, useEffect, useState } from "react";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { InvoiceType } from "../../common/InvoiceType";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calculateInvoiceFxRate, IsNumeric } from "../../util/Utils";
import { makeStyles } from "@mui/styles";
import StyledButton from "../controls/StyledButton";
import { InvoicePayment } from "../../common/InvoicePayment";
import ConfirmationDialog, {
  ConfirmationDialogResult,
} from "../ConfirmationDialog";

const useStyles = makeStyles((theme: Theme) => ({
  selectBox: {
    marginLeft: 8,
    cursor: "pointer",
    fontSize: 14,
    [theme.breakpoints.up("xl")]: {
      marginLeft: 24,
    },
    "& .MuiSelect-select": {
      paddingLeft: 10,
    },
  },
  selectOption: {
    cursor: "pointer",
    padding: 8,
    fontSize: 14,
    display: "block",
  },
  error: {
    color: "red",
  },
}));

type invoiceEntryProps = {
  billingGroups: BillingGroup[];
  invoiceTypes: InvoiceType[];
  currencies: Currency[];
  invoice?: Invoice | null | undefined;
  invoicePayments: InvoicePayment[];
  saveInvoice: any; //event handler
  cancelEdit?: any; //event handler
  formChanged?: any; //event handler
};
const InvoiceEntry: React.FunctionComponent<invoiceEntryProps> = ({
  ...props
}) => {
  const classes = useStyles();
  const [isNew] = useState(
    props.invoice == null || props?.invoice?.Id > 0 ? false : true
  );
  const [sumOfPayments, setSumofPayments] = useState<number>(0);
  const [localFeeErrorMessage, setLocalFeeErrorMessage] = useState<string>('');
  const [open, setOpen] = React.useState(false);

  const {
    handleSubmit,
    register,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    control,
    setValue,
  } = useForm<Invoice>();

  const handleDialogClose = (result: ConfirmationDialogResult) => {
    setOpen(false);
    if (result === ConfirmationDialogResult.Yes) {
      closeInvoice();
    }
  };

  useEffect(() => {
    if (props.invoice && props.invoice?.Id > 0) {
      setValue("IssueDate", props.invoice?.IssueDate ?? new Date(), {
        shouldValidate: false,
      });
      setValue("PeriodEnding", props.invoice?.PeriodEnding ?? new Date(), {
        shouldValidate: false,
      });
      if (props.invoicePayments && props.invoicePayments.length > 0) {
        const paymentsTotal: number = props.invoicePayments.reduce(
          (sum, current) => sum + current.AmountPaid,
          0
        );
        setSumofPayments(paymentsTotal);
      }
    }
  }, [props.invoice, props.invoicePayments, setValue]);


  const validateLocalFeeBalance = (grossRevenue: number, invFeeLocal: number, advFeeWaiver:number) : boolean => {
    const evalResult = (grossRevenue - advFeeWaiver === invFeeLocal);    
    return evalResult;
  }

  const closeInvoice = () => {
    if (props.invoice && props.invoice.Id) {
      props.invoice.isClosed = true;
      props.saveInvoice(props.invoice);
    }
  };

  console.log("Form errors", errors);

  function handleCancel() {
    if (props.cancelEdit) {
      props.cancelEdit();
    }
  }


  const onSubmit: SubmitHandler<Invoice> = (data) => {
    console.log("submitted form", data);
    console.log("original passed in invoice", props.invoice);
    if ((props.invoice?.Id ?? 0) > 0) {
      data.Id = props.invoice?.Id ?? 0;
    }

    props.saveInvoice(data);
  };

  const billingGroupsMenuItems = props.billingGroups.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.GroupName}</em>
      </MenuItem>
    );
  });

  const invoiceTypeMenuItems = props.invoiceTypes.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  const currencyMenuItems = props.currencies.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.ISOCode}</em>
      </MenuItem>
    );
  });

  const validateInvLocal= (amount: any) : boolean => {
    if(IsNumeric(amount)){
        const grossRevenue = getValues("GrossRevenue");
        const advFeeWaiver = getValues("AdvisorFeeWaiver");
        if(validateLocalFeeBalance(grossRevenue,+amount,advFeeWaiver))
        {
          clearErrors("FeeAmountLocal");
          setLocalFeeErrorMessage("");
          console.log("valid");
          return true;
        }else{
          setError("FeeAmountLocal",{
           type:"manual",
            message: "Invoice Fee(Local) must be equal to Gross Revenue minus Advisor Fee Waiver"
          });
          setLocalFeeErrorMessage("Invoice Fee(Local) must be equal to Gross Revenue minus Advisor Fee Waiver");
          console.log("unbalance");
          return false;
        }
    }else{
        setError("FeeAmountLocal",{
        type:"numeric",
        message: "Invoice Fee(Local) must be numeric!"
      });
      setLocalFeeErrorMessage("Invoice Fee(Local) must be numeric!");
      console.log("nonnumeric");
      return false;
    }
  }

  return (
    <Fragment key={`f${props.invoice?.Id}`}>
      <form onSubmit={handleSubmit(onSubmit)} key={`form${props.invoice?.Id}`}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Billing Group *</InputLabel>
                      <Select
                        defaultValue={
                          props.invoice ? props.invoice?.GroupId : 0
                        }
                        variant="outlined"
                        label="Billing Group"
                        {...register("GroupId", {
                          min: {
                            value: 1,
                            message: "Billing Group is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {billingGroupsMenuItems}
                      </Select>
                      {errors.GroupId && (
                        <p className={classes.error}>
                          <em>Billing group is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Currency *</InputLabel>
                      <Select
                        variant="outlined"
                        label="Currency"
                        defaultValue={
                          props.invoice ? props.invoice.CurrencyId : 0
                        }
                        {...register("CurrencyId", {
                          min: { value: 1, message: "Currency is required!" },
                          valueAsNumber: true,
                        })}
                      >
                        {currencyMenuItems}
                      </Select>
                      {errors.CurrencyId && (
                        <p className={classes.error}>
                          <em>Currency is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Invoice Type *</InputLabel>
                      <Select
                        variant="outlined"
                        label="Invoice Type"
                        defaultValue={
                          props.invoice ? props.invoice.InvoiceTypeId : 0
                        }
                        {...register("InvoiceTypeId", {
                          min: {
                            value: 1,
                            message: "Invoice Type is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {invoiceTypeMenuItems}
                      </Select>
                      {errors.InvoiceTypeId && (
                        <p className={classes.error}>
                          <em>Invoice Type is requried!</em>
                        </p>
                      )}
                    </FormControl>
                    {/* )}
            /> */}
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
           
                      <TextField
                        variant="standard"
                        label="Invoice Number"
                        style={{marginTop:10}}
                        inputProps={{ maxLength: 50 }}
                      margin="normal"
                        defaultValue={props.invoice?.InvoiceNumber ?? ""}
                        {...register("InvoiceNumber", { required: true })}
                      />
                      {errors.InvoiceNumber && (
                        <p className={classes.error}>
                          <em>Invoice Number is requried!</em>
                        </p>
                      )}
                    </FormControl>

                    {/* )}
            /> */}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Invoice Fee (USD)"
                      defaultValue={props.invoice?.FeeAmountUSD ?? 0}
                      {...register("FeeAmountUSD", {
                        validate: (v) => IsNumeric(v) || "Fee must be numeric!",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.FeeAmountUSD && (
                      <p className={classes.error}>
                        <em>{errors.FeeAmountUSD.message}</em>
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Invoice Fee(Local)"
                      defaultValue={props.invoice?.FeeAmountLocal ?? 0}
                      {...register("FeeAmountLocal", {
                        validate: (v) => validateInvLocal(v),
                        valueAsNumber: true,
                      })}
                    />
                    {errors.FeeAmountLocal && (
                      <p className={classes.error}>
                        <em>{localFeeErrorMessage}</em>
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <span><em>
                FX Rate Local to USD: {calculateInvoiceFxRate(props.invoice)}</em></span>
                  </Grid>



                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Gross Revenue"
                      defaultValue={props.invoice?.GrossRevenue ?? 0}
                      {...register("GrossRevenue", {
                        validate: (v) =>
                          IsNumeric(v) || "Gross revenue must be numeric!",
                          // onChange:(e) => handleGrossValueChange(e.target.value),
                        valueAsNumber: true,
                      })}
                    />
                    {errors.GrossRevenue && (
                      <p className={classes.error}>
                        <em>{errors.GrossRevenue.message}</em>
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Advisor Fee Waiver"
                      defaultValue={props.invoice?.AdvisorFeeWaiver ?? 0}
                      {...register("AdvisorFeeWaiver", {
                        validate: (v) =>
                          IsNumeric(v) || "Advisor fee waiver must be numeric!",
                          // onChange:(e) => handleAdvisorFeeWaiver(e.target.value),
                        valueAsNumber: true,
                      })}
                    />
                    {errors.AdvisorFeeWaiver && (
                      <p className={classes.error}>
                        <em>{errors.AdvisorFeeWaiver.message}</em>
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Invoice Fee Adjustment(USD)"
                      defaultValue={props.invoice?.FeeAdjustmentUSD ?? 0}
                      {...register("FeeAdjustmentUSD", {
                        validate: (v) =>
                          IsNumeric(v) || "Fee adjustment must be numeric!",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.FeeAdjustmentUSD && (
                      <p className={classes.error}>
                        <em>{errors.FeeAdjustmentUSD.message}</em>
                      </p>
                    )}
                  </Grid>





                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Invoice Fee Adjustment(Local)"
                      defaultValue={props.invoice?.FeeAdjustmentLocal ?? 0}
                      {...register("FeeAdjustmentLocal", {
                        validate: (v) =>
                          IsNumeric(v) || "Fee adjustment must be numeric!",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.FeeAdjustmentLocal && (
                      <p className={classes.error}>
                        <em>{errors.FeeAdjustmentLocal.message}</em>
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="IssueDate"
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          margin="normal"
                          fullWidth={true}
                        >
                          <FormLabel>Issue Date</FormLabel>
                          <ReactDatePicker
                            id="IssueDatePicker"
                            wrapperClassName="react-date-wrapper"
                            placeholderText="Select date"
                            {...register("IssueDate", {
                              valueAsDate: true,
                              required: true,
                            })}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            selected={field.value}
                          />
                        </FormControl>
                      )}
                    />
                    {errors.IssueDate && (
                      <p className={classes.error}>
                        <em>Issue Date is required!</em>
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Billable Market Value(USD)"
                      defaultValue={props.invoice?.BillableMarketValueUSD ?? 0}
                      {...register("BillableMarketValueUSD", {
                        validate: (v) =>
                          IsNumeric(v) ||
                          "Billable market value must be numeric!",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.BillableMarketValueUSD && (
                      <p className={classes.error}>
                        <em>{errors.BillableMarketValueUSD.message}</em>
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="Billable Market Value(Local)"
                      defaultValue={
                        props.invoice?.BillableMarketValueLocal ?? 0
                      }
                      {...register("BillableMarketValueLocal", {
                        validate: (v) =>
                          IsNumeric(v) ||
                          "Billable market value must be numeric!",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.BillableMarketValueLocal && (
                      <p className={classes.error}>
                        <em>{errors.BillableMarketValueLocal.message}</em>
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="PeriodEnding"
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          margin="normal"
                          fullWidth={true}
                        >
                          <FormLabel>Period Ending</FormLabel>
                          <ReactDatePicker
                            className="input"
                            placeholderText="Select date"
                            {...register("PeriodEnding", {
                              required: true,
                              valueAsDate: true,
                            })}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            selected={field.value}
                          />
                          {errors.PeriodEnding && (
                            <p className={classes.error}>
                              <em>Period ending is required!</em>
                            </p>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid container style={{ justifyContent: "center" }}>
                    {props.invoice &&
                      props.invoice.Id !== 0 &&
                      sumOfPayments !== 0 && (
                        <p style={{ marginTop: "10px" }}>
                          <em>
                            ${sumOfPayments.toFixed(2)} has been applied to this
                            invoice.
                          </em>
                        </p>
                      )}
                  </Grid>
                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: "10px" }}
                  >
                    <Grid item>
                      <StyledButton
                        label="Cancel"
                        onClick={() => {
                          handleCancel();
                        }}
                      />
                    </Grid>
                    <Grid item style={{ marginLeft: "15px" }}>
                      <StyledButton
                        disabled={
                          props.invoice?.Id === 0 || sumOfPayments === 0
                        }
                        label="Close Invoice"
                        onClick={() => {
                          setOpen(true);
                        }}
                      />
                    </Grid>
                    <Grid item style={{ marginLeft: "15px" }}>
                      <StyledButton
                        isSubmit={true}
                        label={
                          isNew
                            ? "Add Invoice & Continue"
                            : "Update Invoice & Continue"
                        }
                        onClick={() => {}}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
      <ConfirmationDialog
        open={open}
        onClose={handleDialogClose}
        message={`Close invoice ${props.invoice?.InvoiceNumber}?`}
        title="Close Invoice"
        isYesNo={true}
      />
    </Fragment>
  );
};

export default InvoiceEntry;
