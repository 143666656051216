import { BankAccountInfo } from "../../common/BankAccountInfo";
import { BankDeposit } from "../../common/BankDeposit";
import DoneIcon from "@mui/icons-material/Done";
import { Checkbox, Grid } from "@mui/material";
import MaterialTable from "@material-table/core";
import { materialTableIcons } from "../../common/TableIcons";
import { formatDate } from "../../util/Utils";

type depositListProps = {
  deposits: BankDeposit[];
  bankAccounts: BankAccountInfo[];
  setSelectedDepoist(deposit: BankDeposit): any;
};

const DepositList: React.FunctionComponent<depositListProps> = ({
  ...props
}) => {
  const actions: any[] = [
    () => ({
      // eslint-disable-next-line react/jsx-no-undef
      icon: () => <DoneIcon />,
      tooltip: "Edit",
      onClick: (event: any, row: BankDeposit) => props.setSelectedDepoist(row),
    }),
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialTable
          options={{
            draggable: false,
            thirdSortClick: false,
            search: true,
            sorting: true,
            toolbar: true,
            actionsColumnIndex: 0,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25, 50],
          }}
          onSelectionChange={(rows) => console.log("selection change", rows)}
          localization={{ toolbar: { searchPlaceholder: "Search deposits" } }}
          icons={materialTableIcons}
          columns={[
            {
              title: "Bank Account",
              field: "BankAccountId",
              render: (rowData) => {
                return props.bankAccounts.find(
                  (bg) => bg.Id === rowData.BankAccountId
                )?.Name;
              },
            },
            {
              title: "Deposit Amount",
              field: "DepositAmount",
              render: (rowData) => <>{rowData.DepositAmount}</>,
            },
            {
              title: "Deposit Date",
              render: (rowData) => {
                return formatDate(
                  rowData.DepositDate ? rowData.DepositDate.toString() : "",
                  false
                );
              },
            },
            {
              title: "Notes",
              field: "Notes",
              render: (rowData) => <>{rowData.Notes}</>,
            },
            {
              title: "Is Processed",
              field: "IsProcesed",
              render: (rowData) => (
                <>
                  <Checkbox checked={rowData.isProcessed} />
                </>
              ),
            },
          ]}
          data={props.deposits}
          title="Select a deposit"
          actions={actions}
        ></MaterialTable>
      </Grid>
    </Grid>
  );
};

export default DepositList;
