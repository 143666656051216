import { Invoice } from "../../common/Invoice";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDate } from "../../util/Utils";
import { materialTableIcons } from '../../common/TableIcons';
import { Checkbox, Grid } from "@mui/material";
import { Currency } from "../../common/Currency";
import { BillingGroup } from "../../common/BillingGroup";
import { InvoiceType } from "../../common/InvoiceType";
import MaterialTable from "@material-table/core";

type invoiceListProps = {
  invoices: Invoice[];
  currencies: Currency [];
  billingGroups: BillingGroup[];
  invoiceTypes: InvoiceType[];
  setSelectedInvoice(invoice: Invoice): any;
  // closeInvoice(invoice:Invoice):any;
  deleteInvoice(invoice:Invoice):any;
};

const InvoiceList: React.FunctionComponent<invoiceListProps> = ({
  ...props
}) => {

  console.log("Invoice List invocies:", props.invoices);
  const actions: any [] = [
     () => ({
      icon: () => <EditIcon />,
      tooltip: "Edit",
      onClick: (event: any, row: Invoice) => props.setSelectedInvoice(row)
      
     }),
    //  () => ({
    //   icon: () => <CheckCircleIcon />,
    //   tooltip: "Close",
    //   onClick: (event: any, row: Invoice) => props.closeInvoice(row)
      
    //  }),
     () => ({
      icon: () => <DeleteIcon />,
      tooltip: "Delete",
      onClick: (event: any, row: Invoice) => props.deleteInvoice(row)
      
     }),
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
        <MaterialTable
            options={{
              draggable: false,
              thirdSortClick: false,
              search: true,
              sorting: true,
              toolbar: true,
              actionsColumnIndex: 0,
            }}
            localization={{toolbar: {searchPlaceholder: 'Search invoice number'}}}
            icons={materialTableIcons}
            columns={[
              {
                title: 'Invoice Number',
                field: 'InvoiceNumber',
                render: (rowData) => <>{rowData.InvoiceNumber}</>,
              },
              {
                title: 'Billing Group',
                field: 'GroupId',
                render: (rowData) => {
                  return props.billingGroups.find( bg => bg.Id === rowData.GroupId)?.GroupName;
                  },
              },
              {
                title: 'Invoice Type',
                field: 'Invoice_type',
                render: (rowData) => {
                  return props.invoiceTypes.find( it => it.Id === rowData.InvoiceTypeId)?.Value;
                  },
              },
              {
                title: 'Issue Date',
                render: (rowData) => {
                  return formatDate(rowData.IssueDate ? rowData.IssueDate.toString() : '', false);
                  },
              },
              {
                title: 'Period Ending',
                field: 'PeriodEnding',
                render: (rowData) => {
                  return formatDate(rowData.PeriodEnding ? rowData.PeriodEnding.toString() : '', false);
                  },
              },

              {
                title: 'Currency',
                render: (rowData) => {
                  return props.currencies.find( curr => curr.Id === rowData.CurrencyId)?.ISOCode;
                  },
              },
              {
                title: 'Invoice Fee(USD)',
                render: (rowData) => <>{rowData.FeeAmountUSD}</>,
              },
              {
                title: 'Invoice Fee(Local)',
                render: (rowData) => <>{rowData.FeeAmountLocal}</>,
              },
              {
                title: 'Adjustment Fee(USD)',
                render: (rowData) => <>{rowData.FeeAdjustmentUSD}</>,
              },
              {
                title: 'Adjustment Fee(local)',
                render: (rowData) => <>{rowData.FeeAdjustmentLocal}</>,
              },
              {
                title: 'Gross Revenue',
                render: (rowData) => <>{rowData.GrossRevenue}</>,
              },
              {
                title: 'Advisor Fee Waiver',
                render: (rowData) => <>{rowData.AdvisorFeeWaiver}</>,
              },
              {
                title: "Is Closed",
                field: "isClosed",
                render: (rowData) => (
                  <>
                    <Checkbox checked={rowData.isClosed} />
                  </>
                ),
              },
            ]}
            data={props.invoices}
            title="Invoices"
            actions={actions}
          ></MaterialTable>
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceList;
