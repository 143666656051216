import MaterialTable from "@material-table/core";
import { materialTableIcons } from "../../../common/TableIcons";
import { Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Account } from "../../../common/Account";
import { AccountCurrency } from "../../../common/AccountCurrency";
import { AccountCountry } from "../../../common/AccountCountry";
import { AccountType } from "../../../common/AccountType";
import { AccountVehicle } from "../../../common/AccountVehicle";
import { AccountMandate } from "../../../common/AccountMandate";
import { AssetClass } from "../../../common/AssetClass";
import { Client } from "../../../common/Client";
import { AccountBenchmark } from "../../../common/AccountBenchmark";
import { LetterStrategy } from "../../../common/LetterStrategy";
import { LetterType } from "../../../common/LetterType";

type accountsListProps = {
  accounts: Account[];
  clients: Client[];
  assetClasses: AssetClass[];
  accountMandates: AccountMandate[];
  accountVehicles: AccountVehicle[];
  accountTypes: AccountType[];
  accountCurrencies: AccountCurrency[];
  accountCountries: AccountCountry[];
  accountBenchmarks: AccountBenchmark[];
  letterStrategies: LetterStrategy[];
  letterTypes: LetterType[];
  setSelectedAccount(account: Account): any;
};

const AccountsList: React.FunctionComponent<accountsListProps> = ({
  ...props
}) => {
  const actions: any[] = [
    () => ({
      icon: () => <EditIcon />,
      tooltip: "Edit",
      onClick: (event: any, row: Account) => props.setSelectedAccount(row),
    }),
  ];
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
        <MaterialTable
            options={{
              draggable: false,
              thirdSortClick: false,
              search: true,
              sorting: true,
              toolbar: true,
              actionsColumnIndex: 0,
            }}
            localization={{
              toolbar: { searchPlaceholder: "Search" },
            }}
            columns={[
                {
                  title: "Account Name",
                  field: "AccountName",
                  render: (rowData) => <>{rowData.AccountName}</>,
                },
                {
                  title: "Account Short Name",
                  field: "AccountShortName"//,
                  // render: (rowData) => <>{rowData.AccountShortName}</>,
                },
                {
                  title: "Client",
                  field: "ClientId",
                  render: (rowData) =>{

                    return (<>{props.clients.find((c) => c.Id === rowData.Clientid)?.ClientName}</>);},
                },                
                {
                  title: "Country",
                  field: "CountryId",
                  render: (rowData) =>{

                    return (<>{props.accountCountries.find((c) => c.Id === rowData.CountryId)?.CountryName}</>);},
                },
                {
                  title: "Type",
                  field: "AccountTypeId",
                  render: (rowData) =>{

                    return (<>{props.accountTypes.find((c) => c.Id === rowData.AccountTypeId)?.Value}</>);},
                },
            ]}
            icons={materialTableIcons}
            data={props.accounts}
            title=""
            actions={actions}
          ></MaterialTable>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountsList;
