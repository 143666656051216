import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useState } from "react";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import { BankDeposit } from "../../common/BankDeposit";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { Invoice } from "../../common/Invoice";
import { InvoicePayment } from "../../common/InvoicePayment";
import { InvoiceType } from "../../common/InvoiceType";
import { UnlinkPayment } from "../../common/UnlinkPayment";
import { AppContext } from "../../AppContext";
import InvoiceList from "./InvoiceList";
import InvoiceWithPayments from "./InvoiceWithPayments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftPanel: {
      marginLeft: "-5px",
    },
    rightPanel: {
      marginLeft: "10px",
    },
  })
);

type ManagedLinkedDepositsProps = {
  invoices: Invoice[];
  billingGroups: BillingGroup[];
  bankAccounts: BankAccountInfo[];
  invoiceTypes: InvoiceType[];
  deposits: BankDeposit[];
  currencies: Currency[];
  payments: InvoicePayment[];
  triggerRefresh: any;
  onSetSpinner: any;
};

const ManageLinkedDeposits: React.FunctionComponent<ManagedLinkedDepositsProps> =
  ({ ...props }) => {
    const classes = useStyles();
    const appContext = React.useContext(AppContext);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(
      null
    );
    const [filteredPayments, setFilteredPayments] = useState<InvoicePayment[]>(
      []
    );
    console.log("added for compile, remove later", selectedInvoice);
    const invoiceSelected = (invoice: Invoice) => {
      console.log("invoice selected in manage tab", invoice);
      const paymentsforInvoice = props.payments.filter(
        (p) => p.InvoiceId === invoice.Id
      );
      console.log("payments for selected invoice", paymentsforInvoice);
      setFilteredPayments(paymentsforInvoice);
      setSelectedInvoice(invoice);
    };

    const unlinkPayment = (payment: InvoicePayment) => {
      console.log("in unlink payment", payment);
      const dep = props.deposits.find((d) => d.Id === payment.DepositId);
      console.log("unlink deposit", dep);
      console.log("unlink invoice", selectedInvoice);
      let reOpenDeposit = false;
      let reOpenInvoice = false;
      if(dep?.isProcessed){
        reOpenDeposit = true;
        dep.isProcessed = false;
      }
      if(selectedInvoice?.isClosed){
        reOpenInvoice = true;
        selectedInvoice.isClosed = false;
      }

      if (dep && selectedInvoice) {
        const unlinkPayment: UnlinkPayment = {
          invoicePayment: payment,
          invoice: selectedInvoice,
          deposit: dep,
          //If the deposit or the invoice is currently closed, reopen because this act will mean they have open value on them.
          reOpenInvoice: reOpenInvoice,
          reOpenDeposit: reOpenDeposit
        };

        props.onSetSpinner(true);
        appContext.imReportingStore
          .deleteInvoicePayment(unlinkPayment)
          .then((result) => {
            console.log("Back from post", result);
            props.onSetSpinner(false);
            props.triggerRefresh();

            setFilteredPayments([]);
            setSelectedInvoice(null);
          });
      }
    };

    const clearSelectedInvoice = () => {
      setSelectedInvoice(null);
    };

    return (
      <Grid container>
        <Grid item xs={3} className={classes.leftPanel}>
          <InvoiceList
            billingGroups={props.billingGroups}
            invoices={props.invoices}
            setSelectedInvoice={invoiceSelected}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12} className={classes.rightPanel}>
              <InvoiceWithPayments
                billingGroups={props.billingGroups}
                bankAccounts={props.bankAccounts}
                currencies={props.currencies}
                invoiceTypes={props.invoiceTypes}
                invoice={selectedInvoice}
                filteredPayments={filteredPayments}
                deposits={props.deposits}
                clearSelectedInvoice={clearSelectedInvoice}
                unlinkPayment={unlinkPayment}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default ManageLinkedDeposits;
