import { AppContext, AppStore } from "./AppContext";
// routing
import {
  CssBaseline,
  Theme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Home from "./pages/home";
import Deposits from "./pages/deposits";
import LinkDeposits from "./pages/linkdeposits";
import BillingGroups from "./pages/billinggroups";
import Invoices from "./pages/invoices";
import theme from "./theme";
import { useEffect, useState } from "react";
import { UserInfo } from "./common/UserInfo";
import ProtectedRoute from "./components/controls/ProtectedRoute";
import RevenueAdjustments from "./pages/revenueadjust";
import Accounts from "./pages/accounts";
import PortalUsers from "./pages/portalusers";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export interface AppProps
  extends WithStyles<typeof styles>,
    RouteComponentProps {
  title: string;
  appStore: AppStore;
}

const styles = (theme: Theme) => createStyles({});

const App: React.FunctionComponent<AppProps> = ({ appStore, title }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    (async () => {
      try {        
        const user = await appStore.imReportingUserStore.getUserInfo();
        setUserInfo(await user);
      } catch (error) {
        console.log("GET USER ERROR: ", error);       
      }
    })();
  }, [appStore]);

  return (
    <AppContext.Provider value={appStore}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
            <Router>
              <Switch>
                <Route path="/" exact>
                  <ProtectedRoute userInfo={userInfo}>
                    <Home />
                  </ProtectedRoute>
                </Route>
                <Route path="/invoices">
                  <ProtectedRoute userInfo={userInfo}>
                    <Invoices />
                  </ProtectedRoute>
                </Route>
                <Route path="/deposits">
                  <ProtectedRoute userInfo={userInfo}>
                    <Deposits />
                  </ProtectedRoute>
                </Route>
                <Route path="/linkdeposits">
                  <ProtectedRoute userInfo={userInfo}>
                    <LinkDeposits />
                  </ProtectedRoute>
                </Route>
                <Route path="/revenueadjust">
                  <ProtectedRoute userInfo={userInfo}>
                    <RevenueAdjustments />
                  </ProtectedRoute>
                </Route>
                <Route path="/billinggroups">
                  <ProtectedRoute userInfo={userInfo} adminOnly={true}>
                    <BillingGroups user={userInfo} />
                  </ProtectedRoute>
                </Route>
                <Route path="/accounts">
                  <ProtectedRoute userInfo={userInfo} adminOnly={true}>
                    <Accounts user={userInfo} />
                  </ProtectedRoute>
                </Route>
                <Route path="/portalusers">
                  <ProtectedRoute userInfo={userInfo} adminOnly={true}>
                    <PortalUsers user={userInfo} />
                  </ProtectedRoute>
                </Route>
              </Switch>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppContext.Provider>
  );
};

export default withRouter(withStyles(styles)(App));
