import AppLayout from "../../components/AppLayout";

type HomeProps = {};
const Home: React.FunctionComponent<HomeProps> = () => {
    return (
        <AppLayout>
           <div></div>
        </AppLayout>
    )

}

export default Home;