import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { UserInfo } from "../../common/UserInfo";
import { authorizeUser } from "../../util/Utils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  navBar: {},
  navbarUL: {
    listStyle: "none",
    display: "inline",
  },
  navBarLI: {
    display: "inline",
    marginLeft: "10px",
  },
  navLinks: {
    color: "#FFFFFF",
    textDecoration: "none",
  },
}));

type NavBarProps = {
  userInfo: UserInfo | undefined;

};

const NavBar: React.FunctionComponent<NavBarProps> = ({userInfo}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.navBar}>
        <ul className={classes.navbarUL}>
          <li className={classes.navBarLI}>
            <Link className={classes.navLinks} to="/invoices">
              Invoices
            </Link>
          </li>
          <li className={classes.navBarLI}>
            <Link className={classes.navLinks} to="/deposits">
              Deposits
            </Link>
          </li>
          <li className={classes.navBarLI}>
            <Link className={classes.navLinks} to="/linkdeposits">
              Link Deposits
            </Link>
          </li>
          <li className={classes.navBarLI}>
            <Link className={classes.navLinks} to="/revenueadjust">
               Revenue Adjustments
            </Link>
          </li>
          <li className={classes.navBarLI} hidden={true}>
            <Link className={classes.navLinks} to="/billinggroups" hidden={!authorizeUser(process.env.REACT_APP_ADMIN_ROLE ?? 'Admin',userInfo)}>
              Billing Groups
            </Link>
          </li>
          <li className={classes.navBarLI} hidden={true}>
            <Link className={classes.navLinks} to="/accounts" hidden={!authorizeUser(process.env.REACT_APP_ADMIN_ROLE ?? 'Admin',userInfo)}>
              Accounts
            </Link>
          </li>
          <li className={classes.navBarLI} hidden={true}>
            <Link className={classes.navLinks} to="/portalusers" hidden={!authorizeUser(process.env.REACT_APP_ADMIN_ROLE ?? 'Admin',userInfo)}>
              Users
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBar;
