import { Button } from "@mui/material";
import React from "react";

type StyledButtonProps = {
    label : string;    
    onClick: any;
    className?: any | null | undefined;
    disabled?: boolean ;
    isSubmit?: boolean;
}

const StyledButton: React.FunctionComponent<StyledButtonProps> = ({onClick, label, className, disabled, isSubmit}) => {
    const disabledColor = disabled ? "#999999" : "#003F2D";
    const buttonType = isSubmit ? 'submit' : 'button';
    return (
        <Button
        className={className}
        variant="contained"
        color="primary"
        type ={buttonType}
        onClick={() => onClick()}
        disabled={disabled ?? false}
        style={{
          backgroundColor: disabledColor,//"#003F2D",
          color: "#ffffff",
          fontSize: "14px",
          lineHeight: "24.5px",
          paddingBottom: "6px",
          paddingTop: "6px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
       {label}
      </Button>
    );
}

export default StyledButton;