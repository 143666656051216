import { ApiService } from "../services/ApiService";
import { BillingGroup } from "../common/BillingGroup";
import { Invoice } from "../common/Invoice";
import { Currency } from "../common/Currency";
import { InvoiceType } from "../common/InvoiceType";
import { BankDeposit } from "../common/BankDeposit";
import { BankAccountInfo } from "../common/BankAccountInfo";
import { InvoicePayment } from "../common/InvoicePayment";
import { BillingFrequency } from "../common/BillingFrequency";
import { RevenueCalculationSource } from "../common/RevenueCalculationSource";
import { NewPayment } from "../common/NewPayment";
import { UnlinkPayment } from "../common/UnlinkPayment";
import { BillableMVMethod } from "../common/BillableMVMethod";
import { InvoiceAdjustment } from "../common/InvoiceAdjustment";
import { Account } from "../common/Account";
import { AssetClass } from "../common/AssetClass";
import { AccountMandate } from "../common/AccountMandate";
import { AccountVehicle } from "../common/AccountVehicle";
import { AccountType } from "../common/AccountType";
import { AccountCountry } from "../common/AccountCountry";
import { Client } from "../common/Client";
import { AccountBenchmark } from "../common/AccountBenchmark";
import { AccountCurrency } from "../common/AccountCurrency";
import { LetterType } from "../common/LetterType";
import { LetterStrategy } from "../common/LetterStrategy";
import { MarketOutlook } from "../common/MarketOutlook";
import { PerformanceReview } from "../common/PerformanceReview";
import { MarketReview } from "../common/MarketReview";
import { ReportDeadline } from "../common/ReportDeadline";
import { PortalUser } from "../common/PortalUser";
import PortalRole from "../common/PortalRole";
import PortalUserType from "../common/PortalUserType";
import { PortalUserAccountPermission } from "../common/PortalUserAccountPermission";

export class IMReportingStore {
  private _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  }

  /**
   * Get a list of all billing groups
   *
   */
  public async getBillingGroups(): Promise<BillingGroup[]> {
    const response = await this._apiService.get("billingGroups-get", {
      //params: { id },
    });
    return response.data;
  }

  /**
   * Posts an invoice for insert/update
   *
   */
  public async postBillingGroup(
    deposit: BillingGroup
  ): Promise<BillingGroup[]> {
    const response = await this._apiService.post("billingGroups-post", {
      data: deposit,
    });
    return response.data;
  }

  /**
   * Get a list of all billing currencies
   *
   */
  public async getBillingCurrencies(): Promise<Currency[]> {
    const response = await this._apiService.get("billingCurrencies-get", {});
    return response.data;
  }

  /**
   * Get a list of all invoice types
   *
   */
  public async getInvoiceTypes(): Promise<InvoiceType[]> {
    console.log("in get invoice types");
    const response = await this._apiService.get("invoiceTypes-get", {});
    return response.data;
  }
  /**
   * Get a list of all invoices
   *
   */
  public async getAllInvoices(
    showDeleted?: boolean,
    showClosed?: boolean
  ): Promise<Invoice[]> {
    const response = await this._apiService.get("invoices-get", {
      params: { showDeleted, showClosed },
    });
    console.log("response from get all invoices", response);
    return response.data;
  }

  public async getAllAccounts(): Promise<Account[]> {
    console.log("in get accounts");
    const response = await this._apiService.get("accounts-get", {});
    return response.data;
  }
  
  public async postAccount(account: Account): Promise<Account[]> {
    const response = await this._apiService.post("accounts-post", {
      data: account,
    });
    return response.data;
  }

  
  public async getInvoicesToAdjust(
    showDeleted?: boolean,
  ): Promise<Invoice[]> {
    const response = await this._apiService.get("invoicestoadjust-get", {
      params: { showDeleted },
    });
    console.log("response from get invoices to adjust", response);
    return response.data;
  }

  /**
   * Posts an invoice for insert/update
   *
   */
  public async postInvoice(inv: Invoice): Promise<BillingGroup[]> {
    const response = await this._apiService.post("invoices-post", {
      data: inv,
    });
    return response.data;
  }

  /**
   * Get a list of all bank deposits
   *
   */
  public async getAllBankAccountInfos(): Promise<BankAccountInfo[]> {
    const response = await this._apiService.get("bankAccountInfos-get", {});
    console.log("response from get all bank infos", response);
    return response.data;
  }

  /**
   * Get a list of all bank deposits
   *
   */
  public async getAllBankDeposits(    
    showDeleted?: boolean,
    showClosed?: boolean
    ): Promise<BankDeposit[]> {
    const response = await this._apiService.get("bankDeposits-get", {
      params: { showDeleted, showClosed },
    });
    console.log("response from get all bank deposits", response);
    return response.data;
  }
  public async getBankDepositsForAdjust(): Promise<BankDeposit[]> {
    const response = await this._apiService.get("bankdepositsforadjust-get", {});
    console.log("response from get all bank deposits", response);
    return response.data;
  }

  /**
   * Posts an invoice for insert/update
   *
   */
  public async postBankDeposit(deposit: BankDeposit): Promise<BankDeposit[]> {
    const response = await this._apiService.post("bankDeposit-post", {
      data: deposit,
    });
    return response.data;
  }

  /**
   * Get a list of all Invoice Payments
   *
   */
  public async getAllInvoicePayments(): Promise<InvoicePayment[]> {
    const invoiceId=0;
    const response = await this._apiService.get("invoicePayments-get", {params:{invoiceId}});
    console.log("response from get all invoice payments", response);
    return response.data;
  }
  public async getInvoicePaymentsToAdjust(): Promise<InvoicePayment[]> {
    const response = await this._apiService.get("invoicePaymentsToAdjust-get", {});
    console.log("response from get all invoice payments to adjust", response);
    return response.data;
  }

  /**
   * Get a list of payments for an invoice
   *
   */
  public async getPaymentsForInvocie(
    //TODO fix name
    invoiceId: number
  ): Promise<InvoicePayment[]> {
    const response = await this._apiService.get("invoicePayments-get", {
      params: { invoiceId },
    });
    console.log("response from get all invoice payments", response);
    return response.data;
  }

  public async getAllInvoiceAdjustments(): Promise<InvoiceAdjustment[]> {
    const invoiceId=0;
    const response = await this._apiService.get("invoiceAdjustments-get", {params:{invoiceId}});
    console.log("response from get all invoice adjustments", response);
    return response.data;
  }

  public async postInvoiceAdjustment(
    adjustment: InvoiceAdjustment
  ): Promise<NewPayment[]> {
    const response = await this._apiService.post("invoiceAdjustments-post", {
      data: adjustment,
    });
    return response.data;
  }

  /**
   * Posts an invoice for insert/update
   *
   */
  public async postInvoicePayment(
    payment: NewPayment
  ): Promise<NewPayment[]> {
    const response = await this._apiService.post("invoicePayments-post", {
      data: payment,
    });
    return response.data;
  }

  /**
   * unlinks a payment from an invoice.
   *
   */
  public async deleteInvoicePayment(
    payment: UnlinkPayment
  ): Promise<InvoicePayment[]> {
    const response = await this._apiService.delete("invoicePayments-delete", {
      data: payment,
    });
    return response.data;
  }

  public async deletePortalUser(
    user: PortalUser
  ): Promise<PortalUser[]> {
    const response = await this._apiService.delete("portalUsers-delete", {
      data: user,
    });
    return response.data;
  }


  public async getAllBillingFrequencies(): Promise<BillingFrequency[]> {
    const response = await this._apiService.get("billingFrequencies-get", {});
    console.log("response from get all billing frequencies", response);
    return response.data;
  }

  /**
   * Get a list of all billable mv methods
   *
   */
     public async getBillableMVMethods(): Promise<BillableMVMethod[]> {
      console.log("in get bet billable mv methods");
      const response = await this._apiService.get("billableMVMethods-get", {});
      return response.data;
    }


    public async getClients(): Promise<Client[]> {
      const response = await this._apiService.get("clients-get", {});
      return response.data;
    }

    public async getAssetClasses(): Promise<AssetClass[]> {
      const response = await this._apiService.get("assetClasses-get", {});
      return response.data;
    }


    public async getAccountMandates(): Promise<AccountMandate[]> {
      const response = await this._apiService.get("accountMandates-get", {});
      return response.data;
    }


    public async getAccountVehicles(): Promise<AccountVehicle[]> {
      const response = await this._apiService.get("accountVehicles-get", {});
      return response.data;
    }


    public async getAccountTypes(): Promise<AccountType[]> {
      const response = await this._apiService.get("accountTypes-get", {});
      return response.data;
    }

    public async getAccountCountries(): Promise<AccountCountry[]> {
      const response = await this._apiService.get("accountCountries-get", {});
      return response.data;
    }

    public async getAccountBenchmarks(): Promise<AccountBenchmark[]> {
      const response = await this._apiService.get("accountBenchmarks-get", {});
      return response.data;
    }
    public async getAccountCurrencies(): Promise<AccountCurrency[]> {
      const response = await this._apiService.get("accountCurrencies-get", {});
      return response.data;
    }

    public async getLetterTypes(): Promise<LetterType[]> {
      const response = await this._apiService.get("letterTypes-get", {});
      return response.data;
    }

    public async getLetterStrategies(): Promise<LetterStrategy[]> {
      const response = await this._apiService.get("letterStrategies-get", {});
      return response.data;
    }

    public async getMarketOutlooks(): Promise<MarketOutlook[]> {
      const response = await this._apiService.get("marketOutlooks-get", {});
      return response.data;
    }
    public async getMarketReviews(): Promise< MarketReview []> {
      const response = await this._apiService.get("marketReviews-get", {});
      return response.data;
    }
    public async getPerformanceReviews(): Promise<PerformanceReview[]> {
      const response = await this._apiService.get("performanceReviews-get", {});
      return response.data;
    }
    public async getReportDeadlines(): Promise<ReportDeadline[]> {
      const response = await this._apiService.get("reportDeadlines-get", {});
      return response.data;
    }

    public async getAllPortalUserTypes(): Promise<PortalUserType[]> {
      const response = await this._apiService.get("portalUserTypes-get", {});
      return response.data;
    }

    public async getAllPortalRoles(): Promise<PortalRole[]> {
      const response = await this._apiService.get("portalUserRoles-get", {});
      return response.data;
    }

    public async getAllPortalUsers(): Promise<PortalUser[]> {
      const response = await this._apiService.get("portalUsers-get", {});
      return response.data;
    }
    public async getAllPortalUserAccountPermissions(): Promise<PortalUserAccountPermission[]> {
      const response = await this._apiService.get("portalUserAccountPermissions-get", {});
      return response.data;
    }

    public async postPortalUsers(
      user: PortalUser
    ): Promise<PortalUser[]> {
      const response = await this._apiService.post("portalUsers-post", {
        data: user,
      });
      return response.data;
    }

    public async postPortalUserAccountPermissions(toAdd: PortalUserAccountPermission[], toDelete: PortalUserAccountPermission[]): Promise<PortalUserAccountPermission[]> {
      const body = {toAdd, toDelete};
      const response = await this._apiService.post("portalUserAccountPermissions-post", {
        data: body,
      });
      return response.data;

    }
  public async getAllRevenueCalculationSources(): Promise<
    RevenueCalculationSource[]
  > {
    const response = await this._apiService.get(
      "revenueCalculationSources-get",
      {}
    );
    console.log("response from get all revenue calculation sources", response);
    return response.data;
  }
}
