import { Invoice } from "../../common/Invoice";
import DoneIcon from '@mui/icons-material/Done';
import { Grid } from "@mui/material";
// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { materialTableIcons } from "../../common/TableIcons";
import { BillingGroup } from "../../common/BillingGroup";
type invoiceListProps = {
    invoices: Invoice[];
    billingGroups: BillingGroup[];
    setSelectedInvoice(invoice: Invoice): any;
  
  };

  const InvoiceList: React.FunctionComponent<invoiceListProps> = ({
    ...props
  }) => {
    const actions: any [] = [
        () => ({
         // eslint-disable-next-line react/jsx-no-undef
         icon: () => <DoneIcon />,
         tooltip: "Edit",
         onClick: (event: any, row: Invoice) => props.setSelectedInvoice(row)
         
        }),
     ];
    return (
<>
      <Grid container>
        <Grid item xs={12}>
        <MaterialTable
            options={{
              draggable: false,
              thirdSortClick: false,
              search: true,
              sorting: true,
              toolbar: true,
              actionsColumnIndex: 0,
              pageSize: 10,
              pageSizeOptions: [5, 10, 75, 100],
            }}
            onSelectionChange={(rows) => console.log("selection change", rows)}
            localization={{toolbar: {searchPlaceholder: 'Search invoices'}}}
            icons={materialTableIcons}
            columns={[
              {
                title: 'Invoice Number',
                field: 'InvoiceNumber',
                render: (rowData) => <>{rowData.InvoiceNumber}</>,
              },
              {
                title: 'Billing Group',
                field: 'GroupId',
                render: (rowData) => {
                  return props.billingGroups.find( bg => bg.Id === rowData.GroupId)?.GroupName;
                  },
              },

            ]}
            data={props.invoices}
            title="Select an Invoice"
            actions={actions}
          ></MaterialTable>
        </Grid>
      </Grid>
    </>

    );
  }

  export default InvoiceList;