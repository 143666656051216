import { BankAccountInfo } from "../../../common/BankAccountInfo";
import { BankDeposit } from "../../../common/BankDeposit";
import { BillingGroup } from "../../../common/BillingGroup";
import { Currency } from "../../../common/Currency";
import { Invoice } from "../../../common/Invoice";
import { InvoicePayment } from "../../../common/InvoicePayment";
import { InvoiceAdjustment } from "../../../common/InvoiceAdjustment";
import { InvoiceType } from "../../../common/InvoiceType";
import { SubmitHandler, useForm } from "react-hook-form";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MaterialTable from "@material-table/core";
import { materialTableIcons } from "../../../common/TableIcons";
import {
  Box,
  Card,
  //   CardActions,
  CardContent,
  Checkbox,
  Grid,
  // IconButton,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { useEffect, Fragment, useState } from "react";
import { formatDate, IsNumeric } from "../../../util/Utils";
import StyledButton from "../../controls/StyledButton";

type RevenueAdjustSelectionProps = {
  billingGroups: BillingGroup[];
  bankAccounts: BankAccountInfo[];
  currencies: Currency[];
  invoiceTypes: InvoiceType[];
  invoice: Invoice | null | undefined;
  deposits: BankDeposit[];
  payments: InvoicePayment[];
  postAdjustment: any;
  // allPayments: InvoicePayment[];
  // clearSelectedInvoice: any;
  // clearSelectedDeposit: any;
  // savePayment: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    rightButton: {
      marginLeft: "10px",
    },
    buttonsMargin: {
      marginTop: "20px",
    },
    warning: {
      color: "#C5d152", //"#edf80b",
    },
    errorList: {
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },
    error: {
      color: "red",
    },
  })
);

type selectedItemDetails = {
  sum: number;
  usdAmount: number;
  payments: InvoicePayment[];
};

const RevenueAdjustSelection: React.FunctionComponent<
  RevenueAdjustSelectionProps
> = ({ ...props }) => {
  const classes = useStyles();

  const createEmptyDetails = (): selectedItemDetails => {
    return {
      sum: 0,
      usdAmount: 0,
      payments: [],
    };
  };

  //   const [matchingErrors, setMatchingErrors] = useState<LinkingError[]>([]);
  const [invoiceDetails, setInvoiceDetails] = useState<selectedItemDetails>(
    createEmptyDetails()
  );

  useEffect(() => {
    (async () => {
      if (props.invoice) {
        const tmpInvoicePayments = props.payments.filter(
          (p) => p.InvoiceId === props.invoice?.Id
        );
        const tmpPaymentsAlreadyAssigned = tmpInvoicePayments.reduce(
          (sum, current) => sum + current.AmountPaid,
          0
        );
        const tmpPaymentsAlreadyAssignedUSD = tmpInvoicePayments.reduce(
          (sum, current) => sum + current.AmountPaidUSD,
          0
        );
        // tmpInvoicePayments.forEach((ip) => {
        //   const paymentDeposit = props.deposits.find((d) => d.Id === ip.DepositId);
        //   if(paymentDeposit){
        //       usdAmount += paymentDeposit.DepositAmount;
        //   }
        // })

        setInvoiceDetails({
          sum: tmpPaymentsAlreadyAssigned,
          usdAmount: tmpPaymentsAlreadyAssignedUSD,
          payments: tmpInvoicePayments,
        });
      } else {
        setInvoiceDetails(createEmptyDetails());
      }
    })();
  }, [props.invoice, props.payments, props.deposits]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<InvoiceAdjustment>();

  const onSubmit: SubmitHandler<InvoiceAdjustment> = (data) => {
    console.log("Raw post",data);
    if(props.invoice){
    const toPost: InvoiceAdjustment = {
      Id: 0,
      InvoiceId: props.invoice.Id,
      TotalAppliedToInvoiceLocal: invoiceDetails.sum,
      TotalAppliedToInvoiceUSD: invoiceDetails.usdAmount,
      AdjustmentAmountUSD: data.AdjustmentAmountUSD
    };
    props.postAdjustment(toPost);
  }

  };

  return (

    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.rightButton}>
          <Grid
            container
            xs={12}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
          >
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Selected Invoice
                  </Typography>
                  {props.invoice ? (
                    <Grid container>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={6}>
                            <label>Invoice Number:</label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>{props.invoice.InvoiceNumber}</label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>Issue Date:</label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>
                              {formatDate(
                                props.invoice.IssueDate
                                  ? props.invoice.IssueDate.toString()
                                  : "",
                                false
                              )}
                            </label>
                          </Grid>

                          <Grid item xs={6}>
                            <label>Period Ending:</label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>
                              {formatDate(
                                props.invoice.PeriodEnding
                                  ? props.invoice.PeriodEnding.toString()
                                  : "",
                                false
                              )}
                            </label>
                          </Grid>

                          <Grid item xs={6}>
                            <label>Invoice Type:</label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>
                              {
                                props.invoiceTypes.find(
                                  (bg) => bg.Id === props.invoice?.InvoiceTypeId
                                )?.Value
                              }
                            </label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>Billing Group:</label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>
                              {
                                props.billingGroups.find(
                                  (bg) => bg.Id === props.invoice?.GroupId
                                )?.GroupName
                              }
                            </label>
                          </Grid>

                          <Grid item xs={6}>
                            <label>Invoice Fee Local/USD:</label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>{`${props.invoice.FeeAmountLocal} / ${props.invoice.FeeAmountUSD}`}</label>
                          </Grid>

                          <Grid item xs={6}>
                            <label>Deposits Amount Assigned (Local):</label>
                          </Grid>
                          <Grid item xs={6}>
                            <label>{`${invoiceDetails.sum} / ${invoiceDetails.usdAmount}`}</label>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={9}>
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              margin="none"
                              autoComplete={"off"}
                              label="Amount Paid"
                              disabled={false} 
                              defaultValue={"0"}
                              {...register("AdjustmentAmountUSD", {
                                required: "Adjustment amount is required",                              
                                validate: (v) =>
                                (IsNumeric(v) && v > 0) ||
                                "Adjustment amount must be numeric and greater than zero!",
                                valueAsNumber: true,
                              })}
                            />
                            {errors.AdjustmentAmountUSD && (
                        <p className={classes.error}>
                          <em>{errors.AdjustmentAmountUSD.message}</em>
                        </p>
                      )}
                          </Grid>
                          <Grid item xs={3}>
                            <Box
                              display="flex"
                              justifyContent="right"
                              alignItems="right"
                            >
                              <StyledButton
                                disabled={false} 
                                label="Apply Adjustment"
                                onClick={() => {
                                  console.log("button click");
                                }}
                                isSubmit={true}              
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.buttonsMargin}>
                          <MaterialTable
                            options={{
                              draggable: false,
                              thirdSortClick: false,
                              search: false,
                              sorting: true,
                              toolbar: true,
                              actionsColumnIndex: 0,
                              pageSize: 5,
                              pageSizeOptions: [5, 10, 25, 50],
                            }}
                            icons={materialTableIcons}
                            columns={[
                              {
                                title: "Bank Account",
                                field: "BankAccountId",
                                render: (rowData) => {
                                  return props.bankAccounts.find(
                                    (bg) =>
                                      bg.Id ===
                                      props.deposits.find(
                                        (d) => d.Id === rowData.DepositId
                                      )?.BankAccountId
                                  )?.Name;
                                },
                              },
                              {
                                title: "Amount Applied (Local)",
                                field: "DepositAmount",
                                render: (rowData) => <>{rowData.AmountPaid}</>,
                              },
                              {
                                title: "Amount Applied (USD)",
                                field: "DepositAmount",
                                render: (rowData) => (
                                  <>{rowData.AmountPaidUSD}</>
                                ),
                              },
                              {
                                title: "Deposit Amount(Local)",
                                field: "DepositAmount",
                                render: (rowData) => (
                                  <>
                                    {
                                      props.deposits.find(
                                        (d) => d.Id === rowData.DepositId
                                      )?.DepositAmountLocal
                                    }
                                  </>
                                ),
                              },
                              {
                                title: "Deposit Amount(USD)",
                                field: "DepositAmount",
                                render: (rowData) => (
                                  <>
                                    {
                                      props.deposits.find(
                                        (d) => d.Id === rowData.DepositId
                                      )?.DepositAmount
                                    }
                                  </>
                                ),
                              },
                              {
                                title: "Deposit Date",
                                render: (rowData) => {
                                  const depDate = props.deposits.find(
                                    (d) => d.Id === rowData.DepositId
                                  )?.DepositDate;
                                  return formatDate(
                                    depDate ? depDate.toString() : "",
                                    false
                                  );
                                },
                              },
                              {
                                title: "Notes",
                                field: "Notes",
                                render: (rowData) => <>{rowData.Notes}</>,
                              },
                              {
                                title: "Is Processed",
                                field: "IsProcesed",
                                render: (rowData) => (
                                  <>
                                    <Checkbox
                                      checked={
                                        props.deposits.find(
                                          (d) => d.Id === rowData.DepositId
                                        )?.isProcessed
                                      }
                                    />
                                  </>
                                ),
                              },
                            ]}
                            data={props.payments}
                            title="Deposits Linked to Invoice"
                          ></MaterialTable>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <p>
                      <em>Please select an invoice.</em>
                    </p>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </form>
    </Fragment>
  );
};

export default RevenueAdjustSelection;
