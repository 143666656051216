import { Backdrop, CircularProgress, Grid } from "@mui/material";
import React, { useState } from "react";
import { AppContext } from "../../AppContext";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import { BankDeposit } from "../../common/BankDeposit";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { Invoice } from "../../common/Invoice";
import { InvoicePayment } from "../../common/InvoicePayment";
import { InvoiceType } from "../../common/InvoiceType";
import AppLayout from "../../components/AppLayout";
import RevenueAdjustmentInvoices from "../../components/RevenueAdjust/RevenueAdjustInvoices";

type RevenueAdjustmentProps = {};
const RevenueAdjustments: React.FunctionComponent<
  RevenueAdjustmentProps
> = () => {

  const appContext = React.useContext(AppContext);
  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState<Boolean>(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [billingGroups, setBillingGroups] = useState<BillingGroup[]>([]);
  const [invoiceTypes, setInvoiceTypes] = useState<InvoiceType[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [bankAccountInfos, setBankAccountInfos] = useState<BankAccountInfo[]>([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [invoicePayements, setInvoicePayments] = useState<InvoicePayment[]>([]);
  const [deposits, setDeposits] = useState<BankDeposit[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isMetaDataLoaded) {
        try {
          setShowSpinner(true);
          let [
            billingGroupsResp,
            currenciesResp,
            invoiceTypesResp,
            invoicesResp,
            bankAccountInfosResp,
            depositsResp,
            paymentsResp,
          ] = await Promise.all([
            appContext.imReportingStore.getBillingGroups(),
            appContext.imReportingStore.getBillingCurrencies(),
            appContext.imReportingStore.getInvoiceTypes(),
            appContext.imReportingStore.getInvoicesToAdjust(false),
            appContext.imReportingStore.getAllBankAccountInfos(),
            appContext.imReportingStore.getBankDepositsForAdjust(),
            appContext.imReportingStore.getInvoicePaymentsToAdjust(),
          ]);

          console.log("returned from get invoices to adjust", invoicesResp);
          //Set state on the returned values.
          setBankAccountInfos(bankAccountInfosResp);
          setDeposits(depositsResp);
          setBillingGroups(billingGroupsResp);
          setInvoiceTypes(invoiceTypesResp);
          setCurrencies(currenciesResp);
          setInvoices(invoicesResp);
          setInvoicePayments(paymentsResp);
          setIsMetaDataLoaded(true);
        } catch (fetchError) {
          console.log(fetchError);
          setShowSpinner(false);
        }
        setShowSpinner(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetaDataLoaded]);

  const handleSpinnerAction = (open: boolean) => {
    setShowSpinner(open);
  };
  const triggerDataRefresh = () => {
    setIsMetaDataLoaded(false);
  };
  const handleSpinnerClose = () => {
    setShowSpinner(false);
  };

  console.log("invoices in the adjust screen", invoices);
  return (
    <>
      <AppLayout>
        <Grid container>
          <Grid item xs={12}>
          <RevenueAdjustmentInvoices 
          
          invoices={invoices}
          invoiceTypes={invoiceTypes}
          currencies={currencies}
          payments={invoicePayements}
          billingGroups={billingGroups}
          deposits={deposits.filter(d => d.isProcessed === false)}
          bankAccounts={bankAccountInfos}
          triggerRefresh={triggerDataRefresh}
          onSetSpinner={handleSpinnerAction}
          />
          </Grid>
        </Grid>
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
        onClick={handleSpinnerClose}
      >
        <CircularProgress color="inherit" size="5rem" thickness={1} />
      </Backdrop>
      </AppLayout>
    </>
  );
};

export default RevenueAdjustments;
