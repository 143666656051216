import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AppContext } from "../../AppContext";
import { Account } from "../../common/Account";
import { AccountBenchmark } from "../../common/AccountBenchmark";
import { AccountCountry } from "../../common/AccountCountry";
import { AccountCurrency } from "../../common/AccountCurrency";
import { AccountMandate } from "../../common/AccountMandate";
import { AccountType } from "../../common/AccountType";
import { AccountVehicle } from "../../common/AccountVehicle";
import { AssetClass } from "../../common/AssetClass";
import { Client } from "../../common/Client";
import { LetterStrategy } from "../../common/LetterStrategy";
import { LetterType } from "../../common/LetterType";
import { SnackbarParameters } from "../../common/SnackbarParameters";
import { UserInfo } from "../../common/UserInfo";
import AccountsEntry from "../../components/Accounts/AccountsEntry";
import AccountsList from "../../components/Accounts/AccountsList";
import AppLayout from "../../components/AppLayout";
import StyledButton from "../../components/controls/StyledButton";
import { authorizeUser } from "../../util/Utils";
import CloseIcon from "@mui/icons-material/Close";
import { ReportDeadline } from "../../common/ReportDeadline";
import { PerformanceReview } from "../../common/PerformanceReview";
import { MarketReview } from "../../common/MarketReview";
import { MarketOutlook } from "../../common/MarketOutlook";
type AccountsProps = {
  user: UserInfo | undefined;
};

const Accounts: React.FunctionComponent<AccountsProps> = ({ user }) => {
  const appContext = React.useContext(AppContext);
  const defaultAccount: Account = {
    Id: 0,
    AccountName: undefined,
    AccountShortName: undefined,
    Clientid: undefined,
    CustomerNumber: undefined,
    AssetClassId: 0,
    AccountMandateid: undefined,
    AccountVehicleId: undefined,
    AccountTypeId: undefined,
    BaseCurrencyId: undefined,
    IsActive: true,
    CountryId: undefined,
    APXId: undefined,
    InceptionDate: undefined,
    TerminationDate: undefined,
    ClientNameLabel: undefined,
    ClientNameShortLabel: undefined,
    PrimaryBenchmarkId: undefined,
    SecondaryBenchmarkId: undefined,
    LetterTypeId: undefined,
    LetterStrategyId: undefined,
    MarketReviewId: undefined,
    MarketOutlookId: undefined,
    PerformanceReviewId: undefined,
    ReportDeadlineId: undefined,
    ModifiedBy: undefined,
  };
  const defaultSnackbarParams: SnackbarParameters = {
    isOpen: false,
    message: "",
    alertStyle: "info",
  };
  const isAuthorized = authorizeUser(process.env.ADMIN_ROLE ?? "Admin", user);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [assetClasses, setAssetClasses] = useState<AssetClass[]>([]);
  const [accountMandates, setAccountMandates] = useState<AccountMandate[]>([]);
  const [accountVehicles, setAccountVehicles] = useState<AccountVehicle[]>([]);
  const [accountTypes, setAccountTypes] = useState<AccountType[]>([]);
  const [letterStrategies, setLetterStrategies] = useState<LetterStrategy[]>(
    []
  );
  const [letterTypes, setLetterTypes] = useState<LetterType[]>([]);
  const [marketOutlooks, setMarketOutlooks] = useState<MarketOutlook[]>([]);
  const [marketReviews, setMarketReviews] = useState<MarketReview[]>([]);
  const [performanceReviews, setPerformanceReviews] = useState<
    PerformanceReview[]
  >([]);
  const [reportDeadlines, setReportDeadlines] = useState<ReportDeadline[]>([]);
  const [accountCurrencies, setAccountCurrencies] = useState<AccountCurrency[]>(
    []
  );
  const [accountCountries, setAccountCountries] = useState<AccountCountry[]>(
    []
  );
  const [accountBenchmarks, setAccountBenchmarks] = useState<
    AccountBenchmark[]
  >([]);
  const [selectedAccount, setSelectedAccount] =
    useState<Account>(defaultAccount);
  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState<Boolean>(false);
  const [isEditMode, setEditMode] = useState<Boolean>(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [snackbarParams, setSnackbarParams] = React.useState(
    defaultSnackbarParams
  );

  const accountSelectedForEdit = (account: Account) => {
    setSelectedAccount(account);
    setEditMode(true);
  };
  const cancelEdit = () => {
    setSelectedAccount(defaultAccount);
    setEditMode(false);
  };

  const handleSpinnerClose = () => {
    setShowSpinner(false);
  };
  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarParams(defaultSnackbarParams);
  };

  async function handleSaveAccount(account: Account) {
    setShowSpinner(true);
    appContext.imReportingStore
      .postAccount(account)
      .then((result) => {
        setShowSpinner(false);
        setSelectedAccount(defaultAccount);
        setEditMode(false);
        setIsMetaDataLoaded(false);
      })
      .catch((error) => {
        setShowSpinner(false);
        setSnackbarParams({
          isOpen: true,
          message: `Error saving the account ${error}`,
          alertStyle: "error",
        });
      });
  }

  const snackbarAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackbarClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(
    () => {
      const fetchData = async () => {
        if (!isMetaDataLoaded) {
          try {
            setShowSpinner(true);

            let [
              accountsResp,
              clientsResp,
              assetClassesResp,
              accountMandatesResp,
              accountVehiclesResp,
              accountTypesResp,
              accountCurrenciesResp,
              accountCountriesResp,
              accountBenchmarksResp,
            ] = await Promise.all([
              appContext.imReportingStore.getAllAccounts(),
              appContext.imReportingStore.getClients(),
              appContext.imReportingStore.getAssetClasses(),
              appContext.imReportingStore.getAccountMandates(),
              appContext.imReportingStore.getAccountVehicles(),
              appContext.imReportingStore.getAccountTypes(),
              appContext.imReportingStore.getAccountCurrencies(),
              appContext.imReportingStore.getAccountCountries(),
              appContext.imReportingStore.getAccountBenchmarks(),
            ]);
            //TS/JS was givening a weird index error when the tuple was more than 10, splitting the call up.
            let [
              letterStrategiesResp,
              secondAccountTypes,
              marketOutlookResp,
              marketReviewResp,
              performanceReviewResp,
              reportDeadlineResp,
            ] = await Promise.all([
              appContext.imReportingStore.getLetterStrategies(),
              appContext.imReportingStore.getLetterTypes(),
              appContext.imReportingStore.getMarketOutlooks(),
              appContext.imReportingStore.getMarketReviews(),
              appContext.imReportingStore.getPerformanceReviews(),
              appContext.imReportingStore.getReportDeadlines(),
            ]);
            setAccounts(accountsResp); 
            setClients(clientsResp);
            setAssetClasses(assetClassesResp); 
            setAccountMandates(accountMandatesResp); 
            setAccountVehicles(accountVehiclesResp); 
            setAccountTypes(accountTypesResp); 
            setAccountCurrencies(accountCurrenciesResp); 
            setAccountCountries(accountCountriesResp); 
            setAccountBenchmarks(accountBenchmarksResp); 
            setLetterStrategies(letterStrategiesResp);
            setLetterTypes(secondAccountTypes); 
            setMarketOutlooks(marketOutlookResp);
            setMarketReviews(marketReviewResp);
            setPerformanceReviews(performanceReviewResp);
            setReportDeadlines(reportDeadlineResp);

            setIsMetaDataLoaded(true);
            setEditMode(false);
          } catch (fetchError) {
            setShowSpinner(false);
          }
          setShowSpinner(false);
        }
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMetaDataLoaded]
  );

  console.log("Authorized", isAuthorized);
  return (
    <>
      <AppLayout>
        <Grid container>
          <Grid container alignItems="flex-start" alignContent="center">
            <Grid item xs={12}>
              <Typography variant="h5">Add and Edit Accounts</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            {isEditMode === true ? (
              // <span>edit mode</span>
              <AccountsEntry
                account={selectedAccount}
                clients={clients}
                assetClasses={assetClasses}
                accountMandates={accountMandates}
                accountVehicles={accountVehicles}
                accountTypes={accountTypes}
                accountCurrencies={accountCurrencies}
                accountCountries={accountCountries}
                accountBenchmarks={accountBenchmarks}
                letterTypes={letterTypes}
                letterStrategies={letterStrategies}
                marketOutlooks={marketOutlooks}
                marketReviews={marketReviews}
                performanceReviews={performanceReviews}
                reportDeadlines={reportDeadlines}
                cancelEdit={cancelEdit}
                saveAccount={handleSaveAccount}
              />
            ) : (
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "30px",
                      paddingBottom: "10px",
                    }}
                  >
                    <StyledButton
                      label="Add Account"
                      onClick={() => accountSelectedForEdit(selectedAccount)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AccountsList
                      accounts={accounts}
                      clients={clients}
                      assetClasses={assetClasses}
                      accountMandates={accountMandates}
                      accountVehicles={accountVehicles}
                      accountTypes={accountTypes}
                      accountCurrencies={accountCurrencies}
                      accountCountries={accountCountries}
                      accountBenchmarks={accountBenchmarks}
                      letterTypes={letterTypes}
                      letterStrategies={letterStrategies}
                      setSelectedAccount={accountSelectedForEdit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showSpinner}
          onClick={handleSpinnerClose}
        >
          <CircularProgress color="inherit" size="5rem" thickness={1} />
        </Backdrop>
        <Snackbar
          open={snackbarParams.isOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          action={snackbarAction}
        >
          <Alert severity={snackbarParams.alertStyle}>
            {snackbarParams.message}
          </Alert>
        </Snackbar>
      </AppLayout>
    </>
  );
};

export default Accounts;
