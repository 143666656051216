import React, { Fragment, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import StyledButton from "../../controls/StyledButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { Account } from "../../../common/Account";
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { AccountBenchmark } from "../../../common/AccountBenchmark";
import { AccountCountry } from "../../../common/AccountCountry";
import { AccountCurrency } from "../../../common/AccountCurrency";
import { AccountMandate } from "../../../common/AccountMandate";
import { AccountType } from "../../../common/AccountType";
import { AccountVehicle } from "../../../common/AccountVehicle";
import { AssetClass } from "../../../common/AssetClass";
import { Client } from "../../../common/Client";
import ReactDatePicker from "react-datepicker";
import { LetterStrategy } from "../../../common/LetterStrategy";
import { LetterType } from "../../../common/LetterType";
import { IsNumeric } from "../../../util/Utils";
import { ReportDeadline } from "../../../common/ReportDeadline";
import { PerformanceReview } from "../../../common/PerformanceReview";
import { MarketReview } from "../../../common/MarketReview";
import { MarketOutlook } from "../../../common/MarketOutlook";

const useStyles = makeStyles((theme: Theme) => ({
  selectBox: {
    marginLeft: 8,
    marginTop: 100,
    cursor: "pointer",
    fontSize: 14,
    [theme.breakpoints.up("xl")]: {
      marginLeft: 24,
    },
    "& .MuiSelect-select": {
      paddingLeft: 100,
    },
  },
  selectOption: {
    cursor: "pointer",
    padding: 8,
    fontSize: 14,
    display: "block",
  },
  error: {
    color: "red",
  },
}));

type accountEntryProps = {
  account?: Account;
  clients: Client[];
  assetClasses: AssetClass[];
  accountMandates: AccountMandate[];
  accountVehicles: AccountVehicle[];
  accountTypes: AccountType[];
  accountCurrencies: AccountCurrency[];
  accountCountries: AccountCountry[];
  accountBenchmarks: AccountBenchmark[];
  letterStrategies: LetterStrategy[];
  marketOutlooks: MarketOutlook[];
  marketReviews: MarketReview[];
  performanceReviews: PerformanceReview[];
  reportDeadlines: ReportDeadline[];
  letterTypes: LetterType[];
  saveAccount: any; //event handler
  cancelEdit?: any; //event handler
  // formChanged?: any; //event handler
};

const AccountsEntry: React.FunctionComponent<accountEntryProps> = ({
  ...props
}) => {
  const classes = useStyles();
  const [isNew] = useState(
    props.account == null || props?.account?.Id > 0 ? false : true
  );

  const zeroelement = <MenuItem key="zeroKey" value={0} className={classes.selectOption}>
  <em>--None--</em>
</MenuItem>;

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm < Account>();

  function handleCancel() {
    if (props.cancelEdit) {
      props.cancelEdit();
    }
  }
  useEffect(() => {
    if (props.account && props.account?.Id > 0) {
      setValue("InceptionDate", props.account?.InceptionDate ?? undefined, {
        shouldValidate: false,
      });
      setValue("TerminationDate", props.account?.TerminationDate ?? undefined, {
        shouldValidate: false,
      });
    }
    
  }, [props.account, setValue]);

  const onSubmit: SubmitHandler<Account> = (data) => {
    if ((props.account?.Id ?? 0) > 0) {
      data.Id = props.account?.Id ?? 0;
    }

    props.saveAccount(data);
  };

  const clientMeuItems = props.clients.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.ClientName}</em>
      </MenuItem>
    );
  });

  const assetClassMenuItems = props.assetClasses.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  const accountMandateMenuItems = props.accountMandates.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  const accountVehicleMenuItems = props.accountVehicles.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  const accountTypeMenuItems = props.accountTypes.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });
  const accountCurrencyMenuItems = props.accountCurrencies.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.ISOCode}</em>
      </MenuItem>
    );
  });

  const accountCountryMenuItems = props.accountCountries.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.CountryName}</em>
      </MenuItem>
    );
  });
  const accountBenchmarksMenuItems = props.accountBenchmarks.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Name}</em>
      </MenuItem>
    );
  });

  const letterTypesMenuItems = props.letterTypes.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  const letterStrategiesMenuItems = props.letterStrategies.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  const marketOutlookMenuItems = props.marketOutlooks.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.FileName}</em>
      </MenuItem>
    );
  });

  const marketReviewMenuItems = props.marketReviews.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.FileName}</em>
      </MenuItem>
    );
  });

  const performanceReviewMenuItems = props.performanceReviews.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.FileName}</em>
      </MenuItem>
    );
  });
  const reportDeadlineMenuItems = props.reportDeadlines.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.BusinessDay}</em>
      </MenuItem>
    );
  });


  clientMeuItems.splice(0,0,zeroelement);
  accountCurrencyMenuItems.splice(0,0,zeroelement);
  accountTypeMenuItems.splice(0,0,zeroelement);
  accountVehicleMenuItems.splice(0,0,zeroelement);
  accountMandateMenuItems.splice(0,0,zeroelement);
  assetClassMenuItems.splice(0,0,zeroelement);
  accountBenchmarksMenuItems.splice(0,0,zeroelement);
  accountCountryMenuItems.splice(0,0,zeroelement);
  letterTypesMenuItems.splice(0,0,zeroelement);
  letterStrategiesMenuItems.splice(0,0,zeroelement);
  marketOutlookMenuItems.splice(0,0,zeroelement);
  marketReviewMenuItems.splice(0,0,zeroelement);
  performanceReviewMenuItems.splice(0,0,zeroelement);
  reportDeadlineMenuItems.splice(0,0,zeroelement);

  return (
    <Fragment key={`f${props.account?.Id}`}>
      <form onSubmit={handleSubmit(onSubmit)} key={`form${props.account?.Id}`}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
              <Card>
                  <CardContent>
                      <Grid container spacing={2}>
                          <Grid item xs={6}>
                          <FormControl
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth={true}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        label="Account Name"
                        inputProps={{maxLength:255}}
                        autoComplete={"off"}
                        style={{marginTop:10}}
                        defaultValue={props.account?.AccountName ?? ""}
                        {...register("AccountName", { required: true })}
                      />
                      {errors.AccountName && (
                        <p className={classes.error}>
                          <em>Account Name is requried!</em>
                        </p>
                      )}
                    </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                          <FormControl
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth={true}
                    >
                      <TextField
                        variant="standard"
                        label="Account Short Name"
                        margin="normal"
                        inputProps={{maxLength:255}}
                        autoComplete={"off"}
                        style={{marginTop:10}}
                        defaultValue={props.account?.AccountShortName ?? ""}
                        {...register("AccountShortName", { required: false })}
                      />
                    </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Client</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.Clientid ? props.account?.Clientid : 0
                        }
                        variant="outlined"
                        label="Client"
                        {...register("Clientid", {
                          // min: {
                          //   value: 1,
                          //   message: "Client is required!",
                          // },
                          valueAsNumber: true,
                        })}
                      >
                        {clientMeuItems}
                      </Select>
                      {errors.Clientid && (
                        <p className={classes.error}>
                          <em>Client is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                          <FormControl
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth={true}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        label="Customer Number"
                        inputProps={{maxLength:255}}
                        autoComplete={"off"}
                        style={{marginTop:10}}
                        defaultValue={props.account?.CustomerNumber ?? ""}
                        {...register("CustomerNumber", { required: false })}
                      />
                    </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Asset Class</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.AssetClassId ? props.account?.AssetClassId : 0
                        }
                        variant="outlined"
                        label="Asset Class"
                        {...register("AssetClassId", {
                          min: {
                            value: 1,
                            message: "Asset Class is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {assetClassMenuItems}
                      </Select>
                      {errors.AssetClassId && (
                        <p className={classes.error}>
                          <em>Asset Class is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Account Mandate</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.AccountMandateid ? props.account?.AccountMandateid : 0
                        }
                        variant="outlined"
                        label="Account Mandate"
                        {...register("AccountMandateid", {
                          // min: {
                          //   value: 1,
                          //   message: "Account mandate is required!",
                          // },
                          valueAsNumber: true,
                        })}
                      >
                        {accountMandateMenuItems}
                      </Select>
                      {errors.AccountMandateid && (
                        <p className={classes.error}>
                          <em>Account mandate is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Account Vehicle</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.AccountVehicleId ? props.account?.AccountVehicleId : 0
                        }
                        variant="outlined"
                        label="Account Vehicle"
                        {...register("AccountVehicleId", {
                          // min: {
                          //   value: 1,
                          //   message: "Account vehicle is required!",
                          // },
                          valueAsNumber: true,
                        })}
                      >
                        {accountVehicleMenuItems}
                      </Select>
                      {errors.AccountVehicleId && (
                        <p className={classes.error}>
                          <em>Account vehicle is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Account Type</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.AccountTypeId ? props.account?.AccountTypeId  : 0
                        }
                        variant="outlined"
                        label="Account Vehicle"
                        {...register("AccountTypeId", {
                          // min: {
                          //   value: 1,
                          //   message: "Account type is required!",
                          // },
                          valueAsNumber: true,
                        })}
                      >
                        {accountTypeMenuItems}
                      </Select>
                      {errors.AccountTypeId && (
                        <p className={classes.error}>
                          <em>Account type is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={
                            props.account ? props.account.IsActive: false
                          }
                          {...register("IsActive", { onChange: (e) => {} })}
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Base Currency</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.BaseCurrencyId ? props.account?.BaseCurrencyId  : 0
                        }
                        variant="outlined"
                        label="Base Currency"
                        {...register("BaseCurrencyId", {
                          // min: {
                          //   value: 1,
                          //   message: "Currency is required!",
                          // },
                          valueAsNumber: true,
                        })}
                      >
                        {accountCurrencyMenuItems}
                      </Select>
                      {errors.BaseCurrencyId && (
                        <p className={classes.error}>
                          <em>Currency is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Country</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.CountryId ? props.account?.CountryId  : 0
                        }
                        variant="outlined"
                        label="Country"
                        {...register("CountryId", {
                          // min: {
                          //   value: 1,
                          //   message: "Country is required!",
                          // },
                          valueAsNumber: true,
                        })}
                      >
                        {accountCountryMenuItems}
                      </Select>
                      {errors.CountryId && (
                        <p className={classes.error}>
                          <em>Account type is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                          <FormControl
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth={true}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        label="APX ID"
                        style={{marginTop:10}}
                        inputProps={{maxLength:255}}
                        autoComplete={"off"}
                        defaultValue={props.account?.APXId ?? ""}
                        {...register("APXId", { required: true,validate: (v) => IsNumeric(v) || "Fee must be numeric!",valueAsNumber:true })}
                      />
                      {errors.APXId && (
                        <p className={classes.error}>
                          <em>APX ID is requried and must be numeric!</em>
                        </p>
                      )}
                    </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="InceptionDate"
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          margin="normal"
                          fullWidth={true}
                        >
                          <FormLabel>Inception Date</FormLabel>
                          <ReactDatePicker
                            id="InceptionDatePicker"
                            wrapperClassName="react-date-wrapper"
                            placeholderText="Select date"
                            {...register("InceptionDate", {
                              valueAsDate: true,
                              required: true,
                            })}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            selected={field.value}
                          />
                        </FormControl>
                      )}
                    />
                    {errors.InceptionDate && (
                      <p className={classes.error}>
                        <em>Inception Date is required!</em>
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="TerminationDate"
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          margin="normal"
                          fullWidth={true}
                        >
                          <FormLabel>Termination Date</FormLabel>
                          <ReactDatePicker
                            id="TerminationDatePicker"
                            wrapperClassName="react-date-wrapper"
                            placeholderText="Select date"
                            {...register("TerminationDate", {
                              valueAsDate: true,
                              required: false,
                            })}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            selected={field.value}
                          />
                        </FormControl>
                      )}
                    />
                    {errors.TerminationDate && (
                      <p className={classes.error}>
                        <em>Termination Date is required!</em>
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                          <FormControl
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth={true}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        label="Client Name Label"
                        style={{marginTop:10}}
                        inputProps={{maxLength:255}}
                        autoComplete={"off"}
                        defaultValue={props.account?.ClientNameLabel ?? ""}
                        {...register("ClientNameLabel", { required: false })}
                      />
                    </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                          <FormControl
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth={true}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        label="Client Name Short Label"
                        style={{marginTop:10}}
                        inputProps={{maxLength:255}}
                        autoComplete={"off"}
                        defaultValue={props.account?.ClientNameShortLabel ?? ""}
                        {...register("ClientNameShortLabel", { required: false })}
                      />
                    </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Primary Benchmark</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.PrimaryBenchmarkId ? props.account?.PrimaryBenchmarkId  : 0
                        }
                        variant="outlined"
                        label="Primary Benchmark"
                        {...register("PrimaryBenchmarkId", {
                          valueAsNumber: true,
                        })}
                      >
                        {accountBenchmarksMenuItems}
                      </Select>

                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Secondary Benchmark</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.SecondaryBenchmarkId ? props.account?.SecondaryBenchmarkId  : 0
                        }
                        variant="outlined"
                        label="Secondary Benchmark"
                        {...register("SecondaryBenchmarkId", {
                          valueAsNumber: true,
                        })}
                      >
                        {accountBenchmarksMenuItems}
                      </Select>

                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Letter Type</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.LetterTypeId ? props.account?.LetterTypeId  : 0
                        }
                        variant="outlined"
                        label="Letter Type"
                        {...register("LetterTypeId", {
                          valueAsNumber: true,
                        })}
                      >
                        {letterTypesMenuItems}
                      </Select>

                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Letter Strategy</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.LetterStrategyId ? props.account?.LetterStrategyId  : 0
                        }
                        variant="outlined"
                        label="Letter Strategy"
                        {...register("LetterStrategyId", {
                          valueAsNumber: true,
                        })}
                      >
                        {letterStrategiesMenuItems}
                      </Select>

                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Market Outlook</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.MarketOutlookId ? props.account?.MarketOutlookId  : 0
                        }
                        variant="outlined"
                        label="Market Outlook"
                        {...register("MarketOutlookId", {
                          valueAsNumber: true,
                        })}
                      >
                        {marketOutlookMenuItems}
                      </Select>

                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Market Review</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.MarketReviewId ? props.account?.MarketReviewId  : 0
                        }
                        variant="outlined"
                        label="Market Review"
                        {...register("MarketReviewId", {
                          valueAsNumber: true,
                        })}
                      >
                        {marketReviewMenuItems}
                      </Select>

                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Performance Review</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.PerformanceReviewId ? props.account?.PerformanceReviewId  : 0
                        }
                        variant="outlined"
                        label="Performance Review"
                        {...register("PerformanceReviewId", {
                          valueAsNumber: true,
                        })}
                      >
                        {performanceReviewMenuItems}
                      </Select>

                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Report Deadline</InputLabel>
                      <Select
                        defaultValue={
                          props.account?.ReportDeadlineId ? props.account?.ReportDeadlineId  : 0
                        }
                        variant="outlined"
                        label="Report Deadline"
                        {...register("ReportDeadlineId", {
                          valueAsNumber: true,
                        })}
                      >
                        {reportDeadlineMenuItems}
                      </Select>

                    </FormControl>
                  </Grid>


      <Grid item xs={6}>
                          <FormControl
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth={true}
                    >
                      <TextField
                        variant="standard"
                        margin="normal"
                        label="Report Filename"
                        style={{marginTop:10}}
                        inputProps={{maxLength:255}}
                        autoComplete={"off"}
                        defaultValue={props.account?.ReportFileName ?? ""}
                        {...register("ReportFileName", { required: false })}
                      />
                    </FormControl>
                          </Grid>






                          <Grid
                    container
                    style={{ justifyContent: "center", marginTop: "20px" }}
                  >
                    <Grid item>
                      <StyledButton                         
                          label="Cancel"
                          onClick={() => {
                            handleCancel();
                          }}
                        />
                    </Grid>
                    <Grid item style={{ marginLeft: "15px" }}>
                    <StyledButton
                         isSubmit={true}
                         label={isNew ? 'Add Account & Continue' : 'Update Account& Continue'}
                         onClick={() => {}}
                       />
                    </Grid>
                  </Grid>

                      </Grid>
                  </CardContent>
              </Card>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default AccountsEntry;
