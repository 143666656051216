import { Grid } from "@mui/material";
import { Account } from "../../common/Account";
import PortalRole from "../../common/PortalRole";
import { PortalUser } from "../../common/PortalUser";
import PortalUserType from "../../common/PortalUserType";
import { materialTableIcons } from "../../common/TableIcons";
import MaterialTable from "@material-table/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

type PortalUserProps = {
  portalUsers: PortalUser[];
  portalUserTypes: PortalUserType[];
  portalRoles: PortalRole[];
  accounts: Account[];
  accountsforSelectedUser:any;
  setSelectedUser: any;
  deletePortalUser: any;
};

const PortalUserList: React.FunctionComponent<PortalUserProps> = ({
  ...props
}) => {
  const actions: any[] = [
    () => ({
      icon: () => <EditIcon />,
      tooltip: "Edit",
      onClick: (event: any, row: PortalUser) => props.setSelectedUser(row),
    }),
    () => ({
      icon: () => <AccountBalanceIcon />,
      tooltip: "Manage Accounts",
      onClick: (event: any, row: PortalUser) => props.accountsforSelectedUser(row),
    }),
    () => ({
      icon: () => <DeleteIcon />,
      tooltip: "Delete",
      onClick: (event: any, row: PortalUser) => props.deletePortalUser(row),
    }),
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            options={{
              draggable: false,
              thirdSortClick: false,
              search: true,
              sorting: true,
              toolbar: true,
              actionsColumnIndex: 0,
            }}
            localization={{
              toolbar: { searchPlaceholder: "Search" },
            }}
            icons={materialTableIcons}
            columns={[
              {
                title: "First Name",
                field: "FirstName",                
              },
              {
                title: "Last Name",
                field: "LastName",                
              },
              {
                title: "Email",
                field: "Email",                
              },
              {
                title: "User Type",
                field: "UserTypeId",
                render: (rowData) => {
                  const portalUserType = props.portalUserTypes.find(
                    (pt) => pt.Id === rowData.UserTypeId
                  );
                  return portalUserType?.Value ?? "";
                },
              },
              {
                title: "User Role",
                field: "UserRoleId",
                render: (rowData) => {
                const portalRole= props.portalRoles.find((pr) => pr.Id === rowData.UserRoleId);
                  return portalRole?.Value ?? "";
                },
              },
            ]}
            data={props.portalUsers}
            title="Portal Users"
            actions={actions}
          ></MaterialTable>
        </Grid>
      </Grid>
    </>
  );
};

export default PortalUserList;
