import EditIcon from "@mui/icons-material/Edit";
import MaterialTable from "@material-table/core";
import { formatDate } from "../../util/Utils";
import { materialTableIcons } from "../../common/TableIcons";
import { Checkbox, Grid } from "@mui/material";
import { Currency } from "../../common/Currency";
import { BankDeposit } from "../../common/BankDeposit";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import DeleteIcon from '@mui/icons-material/Delete';

type depositListProps = {
  deposits: BankDeposit[];
  currencies: Currency[];
  bankAccounts: BankAccountInfo[];
  setSelectedDeposit: any;
  deleteDeposit:any;
};

const DepositList: React.FunctionComponent<depositListProps> = ({
  ...props
}) => {
  console.log("Deposit List deposits:", props.deposits);
  console.log("Deposit list props", props);
  const actions: any[] = [
    () => ({
      icon: () => <EditIcon />,
      tooltip: "Edit",
      onClick: (event: any, row: BankDeposit) => props.setSelectedDeposit(row),
    }),
    () => ({
      icon: () => <DeleteIcon />,
      tooltip: "Delete",
      onClick: (event: any, row: BankDeposit) => props.deleteDeposit(row)
      
     }),
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            options={{
              draggable: false,
              thirdSortClick: false,
              search: true,
              sorting: true,
              toolbar: true,
              actionsColumnIndex: 0,
            }}
            localization={{
              toolbar: { searchPlaceholder: "Search" },
            }}
            icons={materialTableIcons}
            columns={[
              {
                title: "Bank Account",
                field: "BankAccountId",
                render: (rowData) => {
                  return props.bankAccounts.find(
                    (bg) => bg.Id === rowData.BankAccountId
                  )?.Name;
                },
              },
              {
                title: "Deposit Amount(Local)",
                field: "DepositAmount",
                render: (rowData) => <>{`${rowData.DepositAmount} ${props.currencies.find((curr) => curr.Id === rowData.DepositCurrencyId)?.ISOCode}`}</>,
              },
              {
                title: "Deposit Amount(USD)",
                field: "DepositAmount",
                render: (rowData) => <>{rowData.DepositAmount}</>,
              },
              {
                title: "Deposit Date",
                render: (rowData) => {
                  return formatDate(
                    rowData.DepositDate ? rowData.DepositDate.toString() : "",
                    false
                  );
                },
              },
              {
                title: "Notes",
                field: "Notes",
                render: (rowData) => <>{rowData.Notes}</>,
              },
              {
                title: "Is Processed",
                field: "IsProcesed",
                render: (rowData) => (
                  <>
                    <Checkbox checked={rowData.isProcessed} />
                  </>
                ),
              },
            ]}
            data={props.deposits}
            title="Deposits"
            actions={actions}
          ></MaterialTable>
        </Grid>
      </Grid>
    </>
  );
};

export default DepositList;
