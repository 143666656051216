import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { AppContext } from "../../AppContext";
import { useState } from "react";
import AppLayout from "../../components/AppLayout";
import { BankDeposit } from "../../common/BankDeposit";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import { Currency } from "../../common/Currency";
import DepositEntry from "../../components/Deposits/DepositEntry";
import DepositList from "../../components/Deposits/DepositList";
import StyledButton from "../../components/controls/StyledButton";
import ConfirmationDialog, { ConfirmationDialogResult } from "../../components/ConfirmationDialog";

type DepositsProps = {};
const Deposits: React.FunctionComponent<DepositsProps> = () => {
  const defaultDeposit: BankDeposit = {
    Id: 0,
    BankAccountId: 0, //FK, non null
    DepositAmount: 0, //non null
    DepositAmountLocal: 0,
    DepositCurrencyId: 0, //FK non null
    DepositDate: new Date(),
    Notes: undefined,
    isProcessed: false,
    isDeleted: false
  };

  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState<Boolean>(false);
  const appContext = React.useContext(AppContext);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState(defaultDeposit);
  const [bankAccountInfos, setBankAccountInfos] = useState<BankAccountInfo[]>(
    []
  );
  const [isEditMode, setEditMode] = useState<Boolean>(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [deposits, setDeposits] = useState<BankDeposit[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteDialogMessage,setDeleteDialogMessage] = React.useState<string>('');
  const [depositToDelete, setDepositToDelete] = React.useState<BankDeposit | null>(null);

  async function handleSaveDeposit(newDespoit: BankDeposit) {
    setShowSpinner(true);
    appContext.imReportingStore.postBankDeposit(newDespoit).then((result) => {
      setShowSpinner(false);
      setSelectedDeposit(defaultDeposit);
      setEditMode(false);
      setIsMetaDataLoaded(false); //Trigger reload
    });
  }
  const depositSelectedForEdit = (deposit: BankDeposit) => {
    setSelectedDeposit(deposit);
    setEditMode(true);
  };

  const cancelEdit = () => {
    setSelectedDeposit(defaultDeposit);
    setEditMode(false);
  };

  const handleSpinnerClose = () => {
    setShowSpinner(false);
  };
  const handleDeleteDeposit = (deposit: BankDeposit) => {
    console.log("Handle delete deposit", deposit);
    setDeleteDialogMessage(`Delete the deposit for ${deposit?.DepositAmount}?`)
    setDeleteDialogOpen(true);
    setDepositToDelete(deposit);

};
const handleDeleteDialogClose = (result: ConfirmationDialogResult, deposit: BankDeposit) => {
  setDeleteDialogOpen(false);
  if(result === ConfirmationDialogResult.Yes){
    console.log("confirmed delete deposit", deposit);
    deposit.isDeleted = true;
    handleSaveDeposit(deposit);
  }
};

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isMetaDataLoaded) {
        try {
          setShowSpinner(true);
          let [bankAccountInfosResp, currenciesResp, depositsResp] =
            await Promise.all([
              appContext.imReportingStore.getAllBankAccountInfos(),
              appContext.imReportingStore.getBillingCurrencies(),
              appContext.imReportingStore.getAllBankDeposits(false,true),
            ]);
          //Set state on the returned values.
          setBankAccountInfos(bankAccountInfosResp);
          setCurrencies(currenciesResp);
          setDeposits(depositsResp);
          console.log("bankaccountinfos", bankAccountInfosResp);
          console.log("currencies", currencies);
          console.log("deposits", depositsResp);
          if (bankAccountInfos.length > 0) {
            defaultDeposit.BankAccountId = bankAccountInfos[0].Id;
          }
          setIsMetaDataLoaded(true);
          setEditMode(false);
        } catch (fetchError) {
          console.log(fetchError);
          setShowSpinner(false);
          // enqueueSnackbar(
          //   `Error retrieving metadata data for the page.`,
          //   { variant: "error" }
          // );
        }
        setShowSpinner(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetaDataLoaded]);

  if (bankAccountInfos) {
    console.log("Billing Groups in main function", bankAccountInfos);
    console.log("Currencies in main function", currencies);
    console.log("Deposits in main function", deposits);
  }

  return (
    <>
      <AppLayout>
        <Grid container>
          <Grid container alignItems="fslex-start" alignContent="center">
            <Grid item xs={12}>
              <Typography variant="h5">Add and Edit Deposits</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={8}>
          <Grid item xs={12}>
            {isEditMode === true ? (
              <Grid item xs={12}>
                <DepositEntry
                  saveDeposit={handleSaveDeposit}
                  bankAccounts={bankAccountInfos}
                  currencies={currencies}
                  deposit={selectedDeposit}
                  cancelEdit={cancelEdit}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "30px",
                      paddingBottom: "10px",
                    }}
                  >
                    <StyledButton
                      label="Add Deposit"
                      onClick={() => depositSelectedForEdit(defaultDeposit)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DepositList
                      deposits={deposits}
                      currencies={currencies}
                      bankAccounts={bankAccountInfos}
                      setSelectedDeposit={depositSelectedForEdit}
                      deleteDeposit={handleDeleteDeposit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        message={deleteDialogMessage}
        title="Delete Invoice"
        isYesNo={true}
        data={depositToDelete}
      />
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
        onClick={handleSpinnerClose}
      >
        <CircularProgress color="inherit" size="5rem" thickness={1} />
      </Backdrop>
      </AppLayout>
    </>
  );
};

export default Deposits;
