import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useState } from "react";
import { AppContext } from "../../AppContext";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import { BankDeposit } from "../../common/BankDeposit";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { Invoice } from "../../common/Invoice";
import { InvoicePayment } from "../../common/InvoicePayment";
import { InvoiceType } from "../../common/InvoiceType";
import { NewPayment } from "../../common/NewPayment";
import DepositList from "./DepositList";
import InvoiceDepositMapping from "./InvoiceDepositMapping";
import InvoiceList from "./InvoiceList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftPanel: {
      marginLeft: "-5px",
    },
    rightPanel: {
      marginLeft: "10px",
    },
    bottomRightPanel: {
      marginLeft: "10px",
      marginTop: "10px",
    },
  })
);

type LinkDepostsToInvoicesProps = {
  invoices: Invoice[];
  billingGroups: BillingGroup[];
  bankAccounts: BankAccountInfo[];
  invoiceTypes: InvoiceType[];
  deposits: BankDeposit[];
  currencies: Currency[];
  payments: InvoicePayment[];
  triggerRefresh: any;
  onSetSpinner:any;
};

const LinkDepostsToInvoices: React.FunctionComponent<LinkDepostsToInvoicesProps> =
  ({
    invoices,
    payments,
    currencies,
    billingGroups,
    bankAccounts,
    invoiceTypes,
    deposits,
    triggerRefresh,
    onSetSpinner
  }) => {
    const classes = useStyles();
    const appContext = React.useContext(AppContext);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(
      null
    );
    const [selectedDeposit, setSelectedDeposit] = useState<BankDeposit | null>(
      null
    );
    const [filteredPayments, setFilteredPayments] = useState<InvoicePayment[]>(
      []
    );

    const invoiceSelected = (invoice: Invoice) => {
      const paymentsforInvoice = payments.filter(
        (p) => p.InvoiceId === invoice.Id
      );
      setFilteredPayments(paymentsforInvoice);
      setSelectedInvoice(invoice);
    };
    const depositSelected = (deposit: BankDeposit) => {
      setSelectedDeposit(deposit);
    };
    const savePayment = (payment: NewPayment) => {
      onSetSpinner(true);
      appContext.imReportingStore.postInvoicePayment(payment).then((result) => {
        //clear the selected items.
        onSetSpinner(false);
        setSelectedInvoice(null);
        setSelectedDeposit(null);

        triggerRefresh();
      });
    };

    const clearSelectedDeposit = () => {
      setSelectedDeposit(null);
    };
    const clearSelectedInvoice = () => {
      setSelectedInvoice(null);
    };
    return (
      <Grid container>
        <Grid item xs={3} className={classes.leftPanel}>
          <InvoiceList
            billingGroups={billingGroups}
            invoices={invoices}
            setSelectedInvoice={invoiceSelected}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12} className={classes.rightPanel}>
              <InvoiceDepositMapping
                currencies={currencies}
                bankAccounts={bankAccounts}
                billingGroups={billingGroups}
                invoiceTypes={invoiceTypes}
                filteredPayments={filteredPayments}
                savePayment={savePayment}
                invoice={selectedInvoice}
                deposit={selectedDeposit}
                allPayments={payments}
                clearSelectedDeposit={clearSelectedDeposit}
                clearSelectedInvoice={clearSelectedInvoice}
              ></InvoiceDepositMapping>
            </Grid>
            <Grid item xs={12} className={classes.bottomRightPanel}>
              <DepositList
                deposits={deposits.filter(d => !d.isDeleted)}
                bankAccounts={bankAccounts}
                setSelectedDepoist={depositSelected}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default LinkDepostsToInvoices;
