import {
  Grid,
  TextField,
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  InputLabel,
  FormLabel,
  Card,
  CardContent,
  FormControlLabel,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import React, { Fragment, useEffect, useState } from "react";
import { Currency } from "../../common/Currency";
import { BankDeposit } from "../../common/BankDeposit";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import { IsNumeric } from "../../util/Utils";
import StyledButton from "../controls/StyledButton";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: "red",
  },
  selectBox: {
    marginLeft: 8,
    cursor: "pointer",
    fontSize: 14,
    [theme.breakpoints.up("xl")]: {
      marginLeft: 24,
    },
    "& .MuiSelect-select": {
      paddingLeft: 10,
    },
  },
  selectOption: {
    cursor: "pointer",
    padding: 8,
    fontSize: 14,
    display: "block",
  },
}));

type depositEntryProps = {
  bankAccounts: BankAccountInfo[];
  currencies: Currency[];
  deposit?: BankDeposit | null | undefined;
  saveDeposit: any; //event handler
  cancelEdit?: any; //event handler
  formChanged?: any; //event handler
};

const DepositEntry: React.FunctionComponent<depositEntryProps> = ({
  ...props
}) => {
  const classes = useStyles();
  const [isNew] = useState(
    props.deposit == null || props?.deposit?.Id > 0 ? false : true
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm<BankDeposit>();

  useEffect(() => {
    if (props.deposit && props.deposit?.Id > 0) {
      setValue("DepositDate", props.deposit?.DepositDate ?? new Date(), {
        shouldValidate: false,
      });
    }
  }, [props.deposit, setValue]);

  console.log("Form errors", errors);
  function handleCancel() {
    if (props.cancelEdit) {
      props.cancelEdit();
    }
  }

  const onSubmit: SubmitHandler<BankDeposit> = (data) => {
    console.log("submitted form", data);
    console.log("original passed in deposit", props.deposit);
    if ((props.deposit?.Id ?? 0) > 0) {
      data.Id = props.deposit?.Id ?? 0;
    }

    props.saveDeposit(data);
  };

  //if a new item, then default the bank id to the first in the list.
  if (isNew && props.deposit && props.bankAccounts.length > 0) {
    props.deposit.BankAccountId = props.bankAccounts[0].Id;
  }

  const bankaccountsMenuItems = props.bankAccounts.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Name}</em>
      </MenuItem>
    );
  });
  const currencyMenuItems = props.currencies.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.ISOCode}</em>
      </MenuItem>
    );
  });

  return (
    <Fragment key={`f${props.deposit?.Id}`}>
      <form onSubmit={handleSubmit(onSubmit)} key={`form${props.deposit?.Id}`}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Bank Account</InputLabel>
                      <Select
                        defaultValue={
                          props.deposit ? props.deposit?.BankAccountId : 0
                        }
                        variant="outlined"
                        label="Bank Account"
                        {...register("BankAccountId", {
                          min: {
                            value: 1,
                            message: "Bank Account is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {bankaccountsMenuItems}
                      </Select>
                      {errors.BankAccountId && (
                        <p className={classes.error}>
                          <em>Bank Account is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Currency *</InputLabel>
                      <Select
                        variant="outlined"
                        label="Currency"
                        defaultValue={
                          props.deposit ? props.deposit.DepositCurrencyId : 0
                        }
                        {...register("DepositCurrencyId", {
                          min: { value: 1, message: "Currency is required!" },
                          valueAsNumber: true,
                        })}
                      >
                        {currencyMenuItems}
                      </Select>
                      {errors.DepositCurrencyId && (
                        <p className={classes.error}>
                          <em>{errors.DepositCurrencyId.message}</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Deposit Amount(USD)"
                      fullWidth={true}
                      defaultValue={props.deposit?.DepositAmount ?? 0}
                      {...register("DepositAmount", {
                        required: "Deposit amount is required",
                        validate: (v) =>
                          (IsNumeric(v) && v > 0) ||
                          "Deposit amount must be numeric and greater than zero!",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.DepositAmount && (
                      <p className={classes.error}>
                        <em>{errors.DepositAmount.message}</em>
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      fullWidth={true}
                      label="Deposit Amount(Local)"
                      defaultValue={props.deposit?.DepositAmountLocal ?? 0}
                      {...register("DepositAmountLocal", {
                        validate: (v) =>
                          (IsNumeric(v) && v > 0) || "Deposit Amount (Local) must be numeric and greater than zero!",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.DepositAmountLocal && (
                      <p className={classes.error}>
                        <em>{errors.DepositAmountLocal.message}</em>
                      </p>
                    )}
                  </Grid>
                  

                  {/* <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      fullWidth={true}
                      label="FX Rate"
                      defaultValue={props.deposit?.FXRate ?? 0}
                      {...register("FXRate", {
                        validate: (v) =>
                          IsNumeric(v) || "FX Rate must be numeric!",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.FXRate && (
                      <p className={classes.error}>
                        <em>{errors.FXRate.message}</em>
                      </p>
                    )}
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={
                            props.deposit ? props.deposit.isFX : false
                          }
                          {...register("isFX", { onChange: (e) => {} })}
                        />
                      }
                      label="Is FX"
                    />
                  </Grid> */}

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Notes"
                      inputProps={{maxLength:255}}
                      fullWidth={true}
                      autoComplete={"off"}
                      defaultValue={props.deposit?.Notes ?? ""}
                      {...register("Notes")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={
                            props.deposit ? props.deposit.isProcessed : false
                          }
                          {...register("isProcessed", { onChange: (e) => {} })}
                        />
                      }
                      label="Is Processed"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="DepositDate"
                      render={({ field }) => (
                        <FormControl
                          variant="outlined"
                          margin="normal"
                          fullWidth={true}
                        >
                          <FormLabel>Deposit Date</FormLabel>
                          <ReactDatePicker
                            autoComplete={"off"}
                            id="DepositDatePicker"
                            wrapperClassName="react-date-wrapper"
                            placeholderText="Select date"
                            {...register("DepositDate", {
                              required: "Deposit Date is required!",
                              valueAsDate: true,
                            })}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            selected={field.value}
                          />
                        </FormControl>
                      )}
                    />
                    {errors.DepositDate && (
                      <p className={classes.error}>
                        <em>{errors.DepositDate.message}</em>
                      </p>
                    )}
                  </Grid>
                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: "20px" }}
                  >
                    <Grid item>
                    <StyledButton                         
                          label="Cancel"
                          onClick={() => {
                            handleCancel();
                          }}
                        />
                    </Grid>
                    <Grid item style={{ marginLeft: "15px" }}>
                      <StyledButton
                         isSubmit={true}
                         label={isNew ? 'Add Deposit & Continue' : 'Update Deposit & Continue'}
                         onClick={() => {}}
                       />

                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default DepositEntry;
