import React, { Fragment, useState } from "react";
import { Theme } from "@mui/material/styles";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { makeStyles } from "@mui/styles";
import { BillingFrequency } from "../../common/BillingFrequency";
import { RevenueCalculationSource } from "../../common/RevenueCalculationSource";
import StyledButton from "../controls/StyledButton";
import { BillableMVMethod } from "../../common/BillableMVMethod";

const useStyles = makeStyles((theme: Theme) => ({
  selectBox: {
    marginLeft: 8,
    cursor: "pointer",
    fontSize: 14,
    [theme.breakpoints.up("xl")]: {
      marginLeft: 24,
    },
    "& .MuiSelect-select": {
      paddingLeft: 10,
    },
  },
  selectOption: {
    cursor: "pointer",
    padding: 8,
    fontSize: 14,
    display: "block",
  },
  error: {
    color: "red",
  },
}));

type billingGroupEntryProps = {
  billingGroup?: BillingGroup;
  currencies: Currency[];
  billingFrequencies: BillingFrequency[];
  billableMVMethods: BillableMVMethod[];
  revenueCalculationSources: RevenueCalculationSource[];
  saveGroup: any; //event handler
  cancelEdit?: any; //event handler
  formChanged?: any; //event handler
};

const BillingGroupsEntry: React.FunctionComponent<billingGroupEntryProps> = ({
  ...props
}) => {
  const classes = useStyles();
  const [isNew] = useState(
    props.billingGroup == null || props?.billingGroup?.Id > 0 ? false : true
  );
  const {
    handleSubmit,
    register,
    formState: { errors },    
  } = useForm<BillingGroup>();


  function handleCancel() {
    if (props.cancelEdit) {
      props.cancelEdit();
    }
  }

  const onSubmit: SubmitHandler<BillingGroup> = (data) => {
    console.log("submitted form", data);
    console.log("original passed in group", props.billingGroup);
    if ((props.billingGroup?.Id ?? 0) > 0) {
      data.Id = props.billingGroup?.Id ?? 0;
    }

    props.saveGroup(data);
  };

  const frequencyMenuItems = props.billingFrequencies.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Frequency}</em>
      </MenuItem>
    );
  });

  const calculationSourceMenuItems = props.revenueCalculationSources.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Source}</em>
      </MenuItem>
    );
  });

  const currencyMenuItems = props.currencies.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.ISOCode}</em>
      </MenuItem>
    );
  });

  const billableMVMethodItems = props.billableMVMethods.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  return (
    <Fragment key={`f${props.billingGroup?.Id}`}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        key={`form${props.billingGroup?.Id}`}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>

                <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth={true}
                    >
                      
                      <TextField
                        variant="standard"
                        margin="normal"
                        label="Group Name"
                        style={{marginTop:10}}
                        inputProps={{maxLength:255}}
                        autoComplete={"off"}
                        defaultValue={props.billingGroup?.GroupName ?? ""}
                        {...register("GroupName", { required: true })}
                      />
                      {errors.GroupName && (
                        <p className={classes.error}>
                          <em>Group Name is requried!</em>
                        </p>
                      )}
                    </FormControl>

                  </Grid>

                <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Billing Frequency</InputLabel>
                      <Select
                        defaultValue={
                          props.billingGroup ? props.billingGroup?.FrequencyId : 0
                        }
                        variant="outlined"
                        label="Billing Frequency"
                        {...register("FrequencyId", {
                          min: {
                            value: 1,
                            message: "Frequency is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {frequencyMenuItems}
                      </Select>
                      {errors.FrequencyId && (
                        <p className={classes.error}>
                          <em>Frequency is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Billable MV Method</InputLabel>
                      <Select
                        defaultValue={
                          props.billingGroup ? props.billingGroup?.BillableMVMethod : 0
                        }
                        variant="outlined"
                        label="Billable MV Method"
                        {...register("BillableMVMethod", {
                          min: {
                            value: 1,
                            message: "Billable VM Method is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {billableMVMethodItems}
                      </Select>
                      {errors.BillableMVMethod && (
                        <p className={classes.error}>
                          <em>Billable MV Method is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Revenue Calculation Source</InputLabel>
                      <Select
                        defaultValue={
                          props.billingGroup ? props.billingGroup?.RevenueCalculationSourceId : 0
                        }
                        label="Revenue Calculation Source"
                        variant="outlined"
                        {...register("RevenueCalculationSourceId", {
                          min: {
                            value: 1,
                            message: "Revenue Calculation Source is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {calculationSourceMenuItems}
                      </Select>
                      {errors.RevenueCalculationSourceId && (
                        <p className={classes.error}>
                          <em>Revenue CalculationSource is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>Currency *</InputLabel>
                      <Select
                        variant="outlined"
                        label="Currency"
                        defaultValue={
                          props.billingGroup ? props.billingGroup.BillingCurrencyId : 0
                        }
                        {...register("BillingCurrencyId", {
                          min: { value: 1, message: "Currency is required!" },
                          valueAsNumber: true,
                        })}
                      >
                        {currencyMenuItems}
                      </Select>
                      {errors.BillingCurrencyId && (
                        <p className={classes.error}>
                          <em>{errors.BillingCurrencyId.message}</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={
                            props.billingGroup ? props.billingGroup.BillingInAdvance : false
                          }
                          {...register("BillingInAdvance", { onChange: (e) => {} })}
                        />
                      }
                      label="Billing Advance"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={
                            props.billingGroup ? props.billingGroup.isAggregate : false
                          }
                          {...register("isAggregate", { onChange: (e) => {} })}
                        />
                      }
                      label="Is Aggregate"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={
                            props.billingGroup ? props.billingGroup.isActive : false
                          }
                          {...register("isActive", { onChange: (e) => {} })}
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Customer Number"
                      inputProps={{maxLength:50}}
                      fullWidth={true}
                      autoComplete={"off"}
                      defaultValue={props.billingGroup?.CustomerNumber ?? ""}
                      {...register("CustomerNumber")}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Project Number"
                      inputProps={{maxLength:50}}
                      fullWidth={true}
                      autoComplete={"off"}
                      defaultValue={props.billingGroup?.ProjectNumber ?? ""}
                      {...register("ProjectNumber")}
                    />
                  </Grid>

                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: "20px" }}
                  >
                    <Grid item>
                      <StyledButton                         
                          label="Cancel"
                          onClick={() => {
                            handleCancel();
                          }}
                        />
                    </Grid>
                    <Grid item style={{ marginLeft: "15px" }}>
                    <StyledButton
                         isSubmit={true}
                         label={isNew ? 'Add Billing Group & Continue' : 'Update Billing Group & Continue'}
                         onClick={() => {}}
                       />
                    </Grid>
                  </Grid>
                  

                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default BillingGroupsEntry;
