import { UserInfo } from "../../common/UserInfo";
import AppLayout from "../../components/AppLayout";
import { authorizeUser } from "../../util/Utils";

import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { AppContext } from "../../AppContext";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { BillingFrequency } from "../../common/BillingFrequency";
import { RevenueCalculationSource } from "../../common/RevenueCalculationSource";
import StyledButton from "../../components/controls/StyledButton";
import BillingGroupsList from "../../components/BillingGroups/BillingGroupsList";
import BillingGroupsEntry from "../../components/BillingGroups/BillingGroupEntry";
import { BillableMVMethod } from "../../common/BillableMVMethod";

type BillingGroupsProps = {
  user: UserInfo | undefined;
};
const BillingGroups: React.FunctionComponent<BillingGroupsProps> = ({
  user,
}) => {
  console.log("Billing Groups User: ", user);
  const appContext = React.useContext(AppContext);
  const defaultBillingGroup: BillingGroup = {
    Id: 0,
    GroupName: "",
    BillingInAdvance: false,
    isAggregate: false,
    FrequencyId: 0, //FK in db
    RevenueCalculationSourceId: 0, //FK in db
    BillingCurrencyId: 0, //FK in db
    isActive: false,
    CustomerNumber: "",
    ProjectNumber: "",
    BillableMVMethod: 0,
  };
  const isAuthorized = authorizeUser(process.env.ADMIN_ROLE ?? "Admin", user); 

  const [billingGroups, setBillingGroups] = useState<BillingGroup[]>([]);
  const [selectedGroup, setSelectedGroup] =
    useState<BillingGroup>(defaultBillingGroup);
  const [billingFrequencies, setBillingFrequencies] = useState<
    BillingFrequency[]
  >([]);
  const [billableMVMethods, setBillableMVMethods] = useState<
    BillableMVMethod[]
  >([]);
  const [revenueCalculationSources, setRevenueCalculationSources] = useState<
    RevenueCalculationSource[]
  >([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState<Boolean>(false);
  const [isEditMode, setEditMode] = useState<Boolean>(false);
  const [showSpinner, setShowSpinner] = React.useState(false);

  const groupSelectedForEdit = (group: BillingGroup) => {
    setSelectedGroup(group);
    setEditMode(true);
  };

  const cancelEdit = () => {
    setSelectedGroup(defaultBillingGroup);
    setEditMode(false);
  };
  const handleSpinnerClose = () => {
    setShowSpinner(false);
  };

  // const handleSpinnerAction = (open: boolean) => {
  //   setShowSpinner(open);
  // };

  async function handleSaveGroup(newGroup: BillingGroup) {
    console.log("In Save group", newGroup);
    setShowSpinner(true);
    appContext.imReportingStore.postBillingGroup(newGroup).then((result) => {
      console.log("Back from post", result);
      //TODO some sort of toast etc. Snackbar?
      setShowSpinner(false);
      groupSelectedForEdit(defaultBillingGroup);
      setEditMode(false);
      setIsMetaDataLoaded(false);
    });
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isMetaDataLoaded) {
        try {
          setShowSpinner(true);
          let [
            billingGroupsResp,
            currenciesResp,
            revenueCalculationSourcesResp,
            billingFrequenciesResp,
            billableMVMethodsResp,
          ] = await Promise.all([
            appContext.imReportingStore.getBillingGroups(),
            appContext.imReportingStore.getBillingCurrencies(),
            appContext.imReportingStore.getAllRevenueCalculationSources(),
            appContext.imReportingStore.getAllBillingFrequencies(),
            appContext.imReportingStore.getBillableMVMethods(),
          ]);
          //Set state on the returned values.
          setBillingGroups(billingGroupsResp);
          setCurrencies(currenciesResp);
          setRevenueCalculationSources(revenueCalculationSourcesResp);
          setBillingFrequencies(billingFrequenciesResp);
          setBillableMVMethods(billableMVMethodsResp);
          setIsMetaDataLoaded(true);
          setEditMode(false);
        } catch (fetchError) {
          console.log(fetchError);
          setShowSpinner(false);
          // enqueueSnackbar(
          //   `Error retrieving metadata data for the page.`,
          //   { variant: "error" }
          // );
        }
        setShowSpinner(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetaDataLoaded]);

  console.log("Authorized", isAuthorized);
  return (
    <>
      <AppLayout>
        <Grid container>
          <Grid container alignItems="flex-start" alignContent="center">
            <Grid item xs={12}>
              <Typography variant="h5">Add and Edit Billing Groups</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            {isEditMode === true ? (
              <Grid item xs={12}>
                <BillingGroupsEntry
                  saveGroup={handleSaveGroup}
                  billingFrequencies={billingFrequencies}
                  revenueCalculationSources={revenueCalculationSources}
                  currencies={currencies}
                  billingGroup={selectedGroup}
                  billableMVMethods={billableMVMethods}
                  cancelEdit={cancelEdit}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "30px",
                      paddingBottom: "10px",
                    }}
                  >
                    <StyledButton
                      label="Add Billing Group"
                      onClick={() => groupSelectedForEdit(selectedGroup)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BillingGroupsList
                      billableMVMethods={billableMVMethods}
                      currencies={currencies}
                      billingGroups={billingGroups}
                      billingFrequencies={billingFrequencies}
                      revenueCalculationSources={revenueCalculationSources}
                      setSelectedGroup={groupSelectedForEdit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showSpinner}
          onClick={handleSpinnerClose}
        >
          <CircularProgress color="inherit" size="5rem" thickness={1} />
        </Backdrop>
      </AppLayout>
    </>
  );
};

export default BillingGroups;
