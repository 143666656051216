import { Account } from "../../common/Account";
import PortalRole from "../../common/PortalRole";
import { PortalUser } from "../../common/PortalUser";
import PortalUserType from "../../common/PortalUserType";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Fragment, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import StyledButton from "../controls/StyledButton";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: "red",
  },
  selectBox: {
    marginLeft: 8,
    cursor: "pointer",
    fontSize: 14,
    [theme.breakpoints.up("xl")]: {
      marginLeft: 24,
    },
    "& .MuiSelect-select": {
      paddingLeft: 10,
    },
  },
  selectOption: {
    cursor: "pointer",
    padding: 8,
    fontSize: 14,
    display: "block",
  },
}));

type PortalUserEntryProps = {
  portalUser: PortalUser | null | undefined;
  portalUserTypes: PortalUserType[];
  portalRoles: PortalRole[];
  accounts: Account[];
  saveUser: any; //event handler
  cancelEdit?: any; //event handler
  formChanged?: any; //event handler
};

const PortalUserEntry: React.FunctionComponent<PortalUserEntryProps> = ({
  ...props
}) => {
  const classes = useStyles();
  const [isNew] = useState(
    props.portalUser == null || props?.portalUser?.Id > 0 ? false : true
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
    //control,
    //setValue,
  } = useForm<PortalUser>();

  //   useEffect(() => {
  //     if (props.portalUser && props.portalUser?.Id > 0) {
  //       setValue("DepositDate", props.deposit?.DepositDate ?? new Date(), {
  //         shouldValidate: false,
  //       });
  //     }
  //   }, [props.deposit, setValue]);
  console.log("Form errors", errors);
  function handleCancel() {
    if (props.cancelEdit) {
      props.cancelEdit();
    }
  }

  const onSubmit: SubmitHandler<PortalUser> = (data) => {
    console.log("submitted form", data);
    console.log("original passed in deposit", props.portalUser);
    if ((props.portalUser?.Id ?? 0) > 0) {
      data.Id = props.portalUser?.Id ?? 0;
    }

    props.saveUser(data);
  };

  if (isNew && props.portalUser && props.portalRoles.length > 0) {
    props.portalUser.UserRoleId = props.portalRoles[0].Id;
  }
  if (isNew && props.portalUser && props.portalUserTypes.length > 0) {
    props.portalUser.UserTypeId = props.portalUserTypes[0].Id;
  }

  const portalRolesMenuItems = props.portalRoles.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  const portalUserTypeMenuItems = props.portalUserTypes.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>{p.Value}</em>
      </MenuItem>
    );
  });

  return (
    <Fragment key={`f${props.portalUser?.Id}`}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        key={`form${props.portalUser?.Id}`}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
              <Grid container spacing={2}>
              <Grid item xs={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="First Name"
                      fullWidth={true}
                      defaultValue={props.portalUser?.FirstName ?? 0}
                      {...register("FirstName", {
                        required: "First Name is required"
                      })}
                    />
                    {errors.FirstName && (
                      <p className={classes.error}>
                        <em>{errors.FirstName.message}</em>
                      </p>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Last Name"
                      fullWidth={true}
                      defaultValue={props.portalUser?.LastName ?? 0}
                      {...register("LastName", {
                        required: "Last Name is required"
                      })} 
                    />
                    {errors.LastName && (
                      <p className={classes.error}>
                        <em>{errors.LastName.message}</em>
                      </p>
                    )}
                  </Grid>
                
                  <Grid item xs={4}>
                    <TextField
                      variant="standard"
                      margin="normal"
                      label="Email"
                      fullWidth={true}
                      defaultValue={props.portalUser?.Email ?? 0}
                      {...register("Email", {
                        required: "Email is required"
                      })} 
                    />
                    {errors.Email && (
                      <p className={classes.error}>
                        <em>{errors.Email.message}</em>
                      </p>
                    )}
                  </Grid>


                  <Grid item xs={6}>
                  <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>User Role</InputLabel>
                      <Select
                        defaultValue={
                          props.portalUser ? props.portalUser?.UserRoleId : 0
                        }
                        variant="outlined"
                        label="User Role"
                        {...register("UserRoleId", {
                          min: {
                            value: 1,
                            message: "User Role is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {portalRolesMenuItems}
                      </Select>
                      {errors.UserRoleId && (
                        <p className={classes.error}>
                          <em>User Role is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                  <FormControl
                      variant="outlined"
                      margin="normal"
                      fullWidth={true}
                    >
                      <InputLabel>User Type</InputLabel>
                      <Select
                        defaultValue={
                          props.portalUser ? props.portalUser?.UserTypeId : 0
                        }
                        variant="outlined"
                        label="User Type"
                        {...register("UserTypeId", {
                          min: {
                            value: 1,
                            message: "User Type is required!",
                          },
                          valueAsNumber: true,
                        })}
                      >
                        {portalUserTypeMenuItems}
                      </Select>
                      {errors.UserTypeId && (
                        <p className={classes.error}>
                          <em>User Type is requried!</em>
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    style={{ justifyContent: "center", marginTop: "20px" }}
                  >
                    <Grid item>
                    <StyledButton                         
                          label="Cancel"
                          onClick={() => {
                            handleCancel();
                          }}
                        />
                    </Grid>
                    <Grid item style={{ marginLeft: "15px" }}>
                      <StyledButton
                         isSubmit={true}
                         label={isNew ? 'Add User & Continue' : 'Update User & Continue'}
                         onClick={() => {}}
                       />

                    </Grid>
                  </Grid>
</Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default PortalUserEntry;