import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { AppContext } from "../../AppContext";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { Invoice } from "../../common/Invoice";
import { InvoiceType } from "../../common/InvoiceType";
import AppLayout from "../../components/AppLayout";
import InvoiceEntry from "../../components/Invoices/invoiceEntry";
import InvoiceList from "../../components/Invoices/InvoiceList";
import StyledButton from "../../components/controls/StyledButton";
import { InvoicePayment } from "../../common/InvoicePayment";
import ConfirmationDialog, { ConfirmationDialogResult } from "../../components/ConfirmationDialog";

type InvoicesProps = {};
const Invoices: React.FunctionComponent<InvoicesProps> = () => {
  const defaultInvoice: Invoice = {
    IssueDate: new Date(),
    PeriodEnding: new Date(),
    Id: 0,
    GroupId: 0,
    CurrencyId: 0,
    InvoiceTypeId: 0,
    InvoiceNumber: "",
    FeeAmountUSD: undefined,
    FeeAmountLocal: undefined,
    FeeAdjustmentUSD: undefined,
    FeeAdjustmentLocal: undefined,
    BillableMarketValueUSD: undefined,
    BillableMarketValueLocal: undefined,
    isClosed: false,
    isDeleted: false,
    GrossRevenue: 0,
    AdvisorFeeWaiver: 0
  };

  const [isMetaDataLoaded, setIsMetaDataLoaded] = useState<Boolean>(false);
  const appContext = React.useContext(AppContext);
  const [selectedInvoice, setSelectedInvoice] = useState(defaultInvoice);
  const [selectedInvoicePayments, setSelectedInvoicePayments] = useState<InvoicePayment []>([]);
  
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [billingGroups, setBillingGroups] = useState<BillingGroup[]>([]);
  const [invoiceTypes, setInvoiceTypes] = useState<InvoiceType[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isEditMode, setEditMode] = useState<Boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteDialogMessage,setDeleteDialogMessage] = React.useState<string>('');
  const [invoiceToDelete, setInvoiceToDelete] = React.useState<Invoice | null>(null);

  async function handleSaveInvoice(newInvoice: Invoice) {
    console.log("In Save invoice", newInvoice);
    setShowSpinner(true);
    appContext.imReportingStore.postInvoice(newInvoice).then((result) => {
      console.log("Back from post", result);
      setShowSpinner(false);
      setSelectedInvoice(defaultInvoice);
      setEditMode(false);
      setIsMetaDataLoaded(false); //Trigger reload
    });
  }

  const handleSpinnerClose = () => {
    setShowSpinner(false);
  };

  const handleDeleteDialogClose = (result: ConfirmationDialogResult, invoice: Invoice) => {
    setDeleteDialogOpen(false);
    if(result === ConfirmationDialogResult.Yes){
      console.log("confirmed delete invoice", invoice);
      invoice.isDeleted = true;
      handleSaveInvoice(invoice);
    }
  };

  const invoiceSelectedForEdit = (invoice: Invoice) => {
    fetchPaymentsForInvoice(invoice)
      .then(() => setEditMode(true))
      .catch(()=> setEditMode(false));    
  };

  const fetchPaymentsForInvoice = async (invoice: Invoice) => {
    try{
      setShowSpinner(true); 
      const paymentsResp = await appContext.imReportingStore.getPaymentsForInvocie(invoice.Id);
      setSelectedInvoicePayments(paymentsResp);
      setSelectedInvoice(invoice);
      setEditMode(true);
    }
    catch (fetchError) {
      console.log("Error getting the payments for an invoice", fetchError);
      setShowSpinner(false);
      setEditMode(false);
    }
    setShowSpinner(false);
  }

  const cancelEdit = () => {
    setSelectedInvoice(defaultInvoice);
    setEditMode(false);
  };

  const handleDeleteInvoice = (invoice: Invoice) => {
      console.log("Handle delete invoice", invoice);
      setDeleteDialogMessage(`Delete invoice ${invoice?.InvoiceNumber}?`)
      setDeleteDialogOpen(true);
      setInvoiceToDelete(invoice);


  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isMetaDataLoaded) {
        try {
          setShowSpinner(true);          
          let [
            billingGroupsResp,
            currenciesResp,
            invoiceTypesResp,
            invoicesResp,
          ] = await Promise.all([
            appContext.imReportingStore.getBillingGroups(),
            appContext.imReportingStore.getBillingCurrencies(),
            appContext.imReportingStore.getInvoiceTypes(),
            appContext.imReportingStore.getAllInvoices(false, true),
          ]);
          //Set state on the returned values.
          setBillingGroups(billingGroupsResp);
          setInvoiceTypes(invoiceTypesResp);
          setCurrencies(currenciesResp);
          setInvoices(invoicesResp);
          setIsMetaDataLoaded(true);
          setEditMode(false);
        } catch (fetchError) {
          console.log(fetchError);
          setShowSpinner(false);
          // enqueueSnackbar(
          //   `Error retrieving metadata data for the page.`,
          //   { variant: "error" }
          // );
        }
        setShowSpinner(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetaDataLoaded]);

  return (
    <>
      <AppLayout>
        <Grid container>
          <Grid container alignItems="flex-start" alignContent="center">
            <Grid item xs={12}>
              <Typography variant="h5">Add and Edit Invoices</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            {isEditMode === true ? (
              <Grid item xs={12}>
                <InvoiceEntry
                  saveInvoice={handleSaveInvoice}
                  billingGroups={billingGroups}
                  invoiceTypes={invoiceTypes}
                  currencies={currencies}
                  invoice={selectedInvoice}
                  invoicePayments={selectedInvoicePayments}
                  cancelEdit={cancelEdit}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "30px",
                      paddingBottom: "10px",
                    }}
                  >
                    <StyledButton
                      label="Add Invoice"
                      onClick={() => invoiceSelectedForEdit(selectedInvoice)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InvoiceList
                      invoices={invoices}
                      currencies={currencies}
                      billingGroups={billingGroups}
                      invoiceTypes={invoiceTypes}
                      setSelectedInvoice={invoiceSelectedForEdit}
                      deleteInvoice={handleDeleteInvoice}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        message={deleteDialogMessage}
        title="Delete Invoice"
        isYesNo={true}
        data={invoiceToDelete}
      />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showSpinner}
          onClick={handleSpinnerClose}
        >
          <CircularProgress color="inherit" size="5rem" thickness={1} />
        </Backdrop>
      </AppLayout>
    </>
  );
};

export default Invoices;
