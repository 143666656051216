import { BillingFrequency } from "../../common/BillingFrequency";
import { BillingGroup } from "../../common/BillingGroup";
// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { Currency } from "../../common/Currency";
import { RevenueCalculationSource } from "../../common/RevenueCalculationSource";
import { materialTableIcons } from "../../common/TableIcons";
import { Checkbox, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { BillableMVMethod } from "../../common/BillableMVMethod";

type billingGroupsProps = {
  billingGroups: BillingGroup[];
  billableMVMethods: BillableMVMethod[];
  currencies: Currency[];
  billingFrequencies: BillingFrequency[];
  revenueCalculationSources: RevenueCalculationSource[];
  setSelectedGroup(group: BillingGroup): any;
};

const BillingGroupsList: React.FunctionComponent<billingGroupsProps> = ({
  ...props
}) => {

  const actions: any[] = [
    () => ({
      icon: () => <EditIcon />,
      tooltip: "Edit",
      onClick: (event: any, row: BillingGroup) => props.setSelectedGroup(row),
    }),
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            options={{
              draggable: false,
              thirdSortClick: false,
              search: true,
              sorting: true,
              toolbar: true,
              actionsColumnIndex: 0,
            }}
            localization={{
              toolbar: { searchPlaceholder: "Search" },
            }}
            icons={materialTableIcons}
            columns={[
              {
                title: "Group Name",
                field: "GroupName",
                render: (rowData) => <>{rowData.GroupName}</>,
              },
              {
                title: "Currency",
                render: (rowData) => {
                  return props.currencies.find(
                    (curr) => curr.Id === rowData.BillingCurrencyId
                  )?.ISOCode;
                },
              },
              {
                title: "Calculation Source",
                render: (rowData) => {
                  return props.revenueCalculationSources.find(
                    (curr) => curr.Id === rowData.RevenueCalculationSourceId
                  )?.Source;
                },
              },
              {
                title: "Frequency",
                field: "FrequencyId",
                render: (rowData) => {
                  return props.billingFrequencies.find(
                    (curr) => curr.Id === rowData.FrequencyId
                  )?.Frequency;
                },
              },

              {
                title: "Billing Advance",
                field: "BillingInAdvance",
                render: (rowData) => (
                  <>
                    <Checkbox checked={rowData.BillingInAdvance} />
                  </>
                ),
              },
              {
                title: "Is Aggregate",
                field: "isAggregate",
                render: (rowData) => (
                  <>
                    <Checkbox checked={rowData.isAggregate} />
                  </>
                ),
              },
              {
                title: "Is Active",
                field: "isActive",
                render: (rowData) => {
                  return (
                    <>
                      <Checkbox checked={rowData.isActive} />
                    </>
                  );
                },
              },
              {
                title: "Billable VM Method",
                render: (rowData) => {
                  return props.billableMVMethods.find(
                    (curr) => curr.Id === rowData.BillableMVMethod
                  )?.Value;
                },
              },
              {
                title: "Customer Number",
                field: "CustomerNumber",
                render: (rowData) => <>{rowData.CustomerNumber}</>,
              },
              {
                title: "Project Number",
                field: "Project Number",
                render: (rowData) => <>{rowData.ProjectNumber}</>,
              },
            ]}
            data={props.billingGroups}
            title="Billing Groups"
            actions={actions}
          ></MaterialTable>
        </Grid>
      </Grid>
    </>
  );
};

export default BillingGroupsList;
