
import { Invoice } from "../common/Invoice";
import { UserInfo } from "../common/UserInfo";



export function formatDate(dateString: string, includeTime: boolean) {
    if (dateString) {
      if (includeTime) {
        return new Date(dateString).toLocaleString('en-US');
      } else {
        return new Date(dateString).toLocaleDateString('en-US');
      }
    }
    return '';
  }

export const IsNumeric = (val: any) : boolean => {
    return !isNaN(val);
}

export const truncateLongText = (str: string, length: number) : String => {
  if(str.length <= length){
    return str;
  }
  else{
    return str.slice(0,length) + '...'
  }
}


export const calculateInvoiceFxRate = (invoice? : Invoice | null | undefined) : number => {
  console.log("convert fx invoice", invoice);
  const local = invoice?.FeeAmountLocal ?? 0;
  const usd = invoice?.FeeAmountUSD ?? 0;
  console.log('local', local)
  console.log('usd', usd);

  if(usd === 0 || local === 0) return 0;//don't divide by 0;

  return Number((local.valueOf()/usd.valueOf()).toFixed(2));

}
export function formatAmount(amount:number, showDollarSign: boolean){
  const usIsNegative = 0 > amount;
  const dollarUSLocale = Intl.NumberFormat('en-US');
  return `${usIsNegative ? '-': ''}${showDollarSign ? '$': ''}${dollarUSLocale.format(Math.abs((Number(amount.toFixed(2)))))}`;
}
export const authorizeUser = (role: string, user?: UserInfo ): boolean => {
  console.log("auth user 1", role);
  console.log("auth user 2", user);
   if(user){
     const ix = user.applicationRoles?.filter(r => r.ApplicationRole === role).length;
    if( ix > 0 ) return true;
  }
  return false;
}