import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Account } from "../../common/Account";
import { PortalUser } from "../../common/PortalUser";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { PortalUserAccountPermission } from "../../common/PortalUserAccountPermission";
import StyledButton from "../controls/StyledButton";

type SelectableAccount = {
  isSelected: boolean;
  account: Account;
};

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: "red",
  },
  selectBox: {
    marginLeft: 8,
    cursor: "pointer",
    fontSize: 14,
    [theme.breakpoints.up("xl")]: {
      marginLeft: 24,
    },
    "& .MuiSelect-select": {
      paddingLeft: 10,
    },
  },
  selectOption: {
    cursor: "pointer",
    padding: 8,
    fontSize: 14,
    display: "block",
  },
  button: {
    marginTop: "15px",
  }
}));

type PortalUserAccountsProps = {
  portalUser: PortalUser | null | undefined;
  allUsers: PortalUser[];
  portalUserAccounts: PortalUserAccountPermission[];
  accounts: Account[];
  saveAccounts: any; //event handler
};

const PortalUserAccounts: React.FunctionComponent<PortalUserAccountsProps> = ({
  ...props
}) => {
  const classes = useStyles();

  const [availableAccounts, setAvailableAccounts] = React.useState<
    SelectableAccount[]
  >([]);
  const [selectedUserId, setSelectedUserId] = React.useState<number>(0);
  const [selectedAccounts, setSelectedAccounts] = React.useState<
    SelectableAccount[]
  >([]);

//   useEffect(() => {
//     if (props.accounts) {
//       let selectedAccounts = props.accounts.map((a) => {
//         return {
//           isSelected: false,
//           account: a,
//         } as SelectableAccount;
//       });
//       setAvailableAccounts(sortItemsList(selectedAccounts));
//     }
//   }, [props.accounts]);


    useEffect(() => {
        if(props.portalUser && props.portalUser.Id > 0){          
            setSelectedUserId(props.portalUser.Id);
        }else{
            setSelectedUserId(props.allUsers[0].Id);
        }
    }, [props.portalUser]);


    useEffect(() => {
        if(selectedUserId > 0){
            filterAccountLists(selectedUserId);
        }
    }, [selectedUserId]);

  const userMenuItems = props.allUsers.map((p, ix) => {
    return (
      <MenuItem key={ix + 100} value={p.Id} className={classes.selectOption}>
        <em>
          {p.FirstName} {p.LastName}
        </em>
      </MenuItem>
    );
  });

  const sortItemsList = (list: SelectableAccount[]): SelectableAccount[] => {
    return list.sort((a, b) =>
      (a.account.AccountName ?? "").localeCompare(b.account.AccountName ?? "")
    );
  };

  const handleAvailableToggle = (value: number) => () => {
    const checkedItem = availableAccounts.find((a) => a.account.Id === value);
    if (checkedItem) {
      const newAvailable = availableAccounts.filter(
        (a) => a.account.Id !== value
      );
      checkedItem.isSelected = true;
      selectedAccounts.push(checkedItem);
      const newSelected = [...selectedAccounts];
      setAvailableAccounts(sortItemsList(newAvailable));
      setSelectedAccounts(sortItemsList(newSelected));
    }
  };

  const handleSelectedToggle = (value: number) => () => {
    const checkedItem = selectedAccounts.find((a) => a.account.Id === value);
    if (checkedItem) {
      const newSelected = selectedAccounts.filter(
        (a) => a.account.Id !== value
      );
      checkedItem.isSelected = false;
      availableAccounts.push(checkedItem);
      const newAvailable = [...availableAccounts];
      setAvailableAccounts(sortItemsList(newAvailable));
      setSelectedAccounts(sortItemsList(newSelected));
    }
  };


  const handleSave = () => {
    if(selectedUserId && selectedUserId > 0){
      //Get a list he came in with
      const previousPermissionAccountIds = props.portalUserAccounts.filter((u) => u.UserId === selectedUserId).map((u) => u.ClientAccountId);
      const existingAndNewAccountIds = selectedAccounts.map((a) => a.account.Id);
      const accountsToDeleteIds = previousPermissionAccountIds.filter((a) => !existingAndNewAccountIds.includes(a));


      const accountsToSave = selectedAccounts.map((a) => {return {UserId: selectedUserId, ClientAccountId: a.account.Id }});
      const filterToJustTheNew = accountsToSave.filter((a) => !previousPermissionAccountIds.includes(a.ClientAccountId));
      const accountsToDelete = props.portalUserAccounts.filter((u) => u.UserId === selectedUserId && accountsToDeleteIds.includes(u.ClientAccountId));
      props.saveAccounts(filterToJustTheNew, accountsToDelete);
    }
    else{
      console.log("Save failed.  Please select a user.");
    }
  }

  const filterAccountLists = (userId: number) => {
    const filteredPermissionAccounts = props.portalUserAccounts.filter((u) => u.UserId === userId).map((u) => u.ClientAccountId);
    const userAccounts = props.accounts.filter((a) => filteredPermissionAccounts.includes(a.Id));
    const availableAccounts = props.accounts.filter((a) => !filteredPermissionAccounts.includes(a.Id));

    const filteredAvailable: SelectableAccount[] = availableAccounts.map((a) => {
        return {
            isSelected: false,
            account: a,
        } as SelectableAccount;
    }   );

    const filteredForUser: SelectableAccount[] = userAccounts.map((a) => {
        return {
            isSelected: true,
            account: a,
        } as SelectableAccount;
    }   );

    setAvailableAccounts(sortItemsList(filteredAvailable));
    setSelectedAccounts(sortItemsList(filteredForUser));
  }



  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }}>
        <Grid container spacing={2} margin={2}>
          <Grid item xs={10}>
            <FormControl variant="outlined" margin="normal" fullWidth={true}>
              <InputLabel>User</InputLabel>
              <Select
                value={selectedUserId}
                variant="outlined"
                label="User"
                onChange={(e) => setSelectedUserId(+e.target.value)}
              >
                {userMenuItems}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
          <StyledButton
                        className={classes.button}
                      label="Add Account(s)"
                      onClick={handleSave}
                    />
          </Grid>
        </Grid>
        <Grid container spacing={1} margin={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Available Accounts</Typography>
            <List sx={{ width:"90%", bgcolor: "background.paper" }}>
              {availableAccounts.map((a, ix) => {
                const labelId = `available-list-label-${a.account.Id}`;
                return (
                  <ListItem key={a.account.Id}>
                    <ListItemButton
                      role={undefined}
                      onClick={handleAvailableToggle(a.account.Id)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={a.isSelected}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={a.account.AccountName}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">User Accounts</Typography>
            <List sx={{ width: "90%", bgcolor: "background.paper" }}>
              {selectedAccounts.map((a, ix) => {
                const labelId = `selected-list-label-${a.account.Id}`;
                return (
                  <ListItem key={a.account.Id}>
                    <ListItemButton
                      role={undefined}
                      onClick={handleSelectedToggle(a.account.Id)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={a.isSelected}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={a.account.AccountName}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PortalUserAccounts;
