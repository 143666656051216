import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { BankAccountInfo } from "../../common/BankAccountInfo";
import { BankDeposit } from "../../common/BankDeposit";
import { BillingGroup } from "../../common/BillingGroup";
import { Currency } from "../../common/Currency";
import { Invoice } from "../../common/Invoice";
import { InvoicePayment } from "../../common/InvoicePayment";
import { InvoiceType } from "../../common/InvoiceType";
import { formatDate, IsNumeric, truncateLongText } from "../../util/Utils";
import ClearIcon from "@mui/icons-material/Clear";
import StyledButton from "../controls/StyledButton";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Fragment, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { NewPayment } from "../../common/NewPayment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    rightButton: {
      marginLeft: "10px",
    },
    buttonsMargin: {
      marginTop: "10px",
    },
    warning: {
      color: "#C5d152", //"#edf80b",
    },
    errorList: {
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },
    error: {
      color: "red",
    },
  })
);

type invoiceDepositMappingProps = {
  billingGroups: BillingGroup[];
  bankAccounts: BankAccountInfo[];
  currencies: Currency[];
  invoiceTypes: InvoiceType[];
  invoice: Invoice | null | undefined;
  deposit: BankDeposit | null | undefined;
  filteredPayments: InvoicePayment[];
  allPayments: InvoicePayment[];
  clearSelectedInvoice: any;
  clearSelectedDeposit: any;
  savePayment: any;
};

type selectedItemDetails = {
  sum: number;
  openAmount: number;
  payments: InvoicePayment[];
};

interface LinkingError {
  message: string;
  isError: Boolean;
}

const InvoiceDepositMapping: React.FunctionComponent<invoiceDepositMappingProps> =
  ({ ...props }) => {
    const createEmptyDetails = (): selectedItemDetails => {
      return {
        sum: 0,
        openAmount: 0,
        payments: [],
      };
    };

    const [matchingErrors, setMatchingErrors] = useState<LinkingError[]>([]);
    const [invoiceDetails, setInvoiceDetails] = useState<selectedItemDetails>(
      createEmptyDetails()
    );
    const [depositDetails, setDepositDetails] = useState<selectedItemDetails>(
      createEmptyDetails()
    );

    const classes = useStyles();

    const errorItems = matchingErrors.map((p, ix) => {
      return (
        <li
          key={ix + 100}
          className={p.isError ? classes.error : classes.warning}
        >
          <em>{p.message}</em>
        </li>
      );
    });

    const {
      handleSubmit,
      register,
      setError,
      reset,
      getValues,
      clearErrors,
      formState: { errors },
    } = useForm<InvoicePayment>();

    const onSubmit: SubmitHandler<InvoicePayment> = (data) => {
      if (props.deposit && props.invoice) {
        const amtPaid = +data.AmountPaid;
        let isClosingInvoice = false;
        let isClosingDeposit = false;

        if (invoiceDetails.openAmount <= amtPaid) {
          props.invoice.isClosed = true;
          isClosingInvoice = true;
        }
        if (depositDetails.openAmount <= amtPaid) {
          props.deposit.isProcessed = true;
          isClosingDeposit = true;
        }
        
        //USD Calculation for amount paid
        const fxRate = props.deposit.DepositAmountLocal / props.deposit.DepositAmount;
        const usdApplied = Number((amtPaid.valueOf() / fxRate.valueOf()).toFixed(2));

        const newPayment: InvoicePayment = {
          Id: 0,
          InvoiceId: props.invoice?.Id ?? 0,
          DepositId: props.deposit?.Id ?? 0,
          AmountPaid: amtPaid,
          AmountPaidUSD: usdApplied, //TODO for now.
          Notes: data.Notes,
        };

        const payment: NewPayment = {
          invoicePayment: newPayment,
          invoice: props.invoice,
          deposit: props.deposit,
          closingInvoice: isClosingInvoice,
          closingDeposit: isClosingDeposit
        }
        props.savePayment(payment);
        console.log('Saving payment', payment);
        console.log("invoice", props.invoice);
        console.log("deposit", props.deposit);
        reset();
      }
    };

    const validateAmountPaid = (amount: string) => {
      if (!amount || amount === "") {
        setError("AmountPaid", {
          type: "manual",
          message: "Amount Paid is required!",
        });
        return;
      }
      if (!IsNumeric(amount)) {
        setError("AmountPaid", {
          type: "manual",
          message: "Amount Paid must be numeric!",
        });
        return;
      }

      if (!props.invoice || !props.deposit) {
        setError("AmountPaid", {
          type: "manual",
          message: "Either the deposit or the invoice has not been selected!",
        });
        return;
      }

      if (+amount > depositDetails.openAmount) {
        setError("AmountPaid", {
          type: "manual",
          message:
            "Amount Paid must be less than the amount remaining on the deposit!",
        });
        return;
      }
      console.log("here2");
      if (+amount > invoiceDetails.openAmount) {
        setError("AmountPaid", {
          type: "manual",
          message:
            "Amount Paid must be less than the amount remaining on the invoice!",
        });
        return;
      }

      if (+amount <= 0) {
        setError("AmountPaid", {
          type: "manual",
          message: "Amount paid must be greater than 0!",
        });
        return;
      }
      clearErrors("AmountPaid");
      return;
    };

    useEffect(() => {
      (async () => {
        try {
          const tempErrors: LinkingError[] = [];
          if (props.invoice) {
            const tmpInvoicePayments = props.allPayments.filter(
              (p) => p.InvoiceId === props.invoice?.Id
            );
            const tmpPaymentsAlreadyAssigned = tmpInvoicePayments.reduce(
              (sum, current) => sum + current.AmountPaid,
              0
            );
            let remainingToDeposit = Number(
              (
                (props.invoice?.FeeAmountLocal ?? 0).valueOf() -
                tmpPaymentsAlreadyAssigned.valueOf()
              ).toFixed(2)
            );

            if (remainingToDeposit <= 0) {
              tempErrors.push({
                message:
                  "The selected invoice has already been fully allocated.",
                isError: true,
              });
            }

            if (
              tmpInvoicePayments.find((i) => i.DepositId === props.deposit?.Id)
            ) {
              tempErrors.push({
                message:
                  "The selected deposit is already matched to this invoice.",
                isError: true,
              });
            }

            setInvoiceDetails({
              sum: tmpPaymentsAlreadyAssigned,
              openAmount: remainingToDeposit,
              payments: tmpInvoicePayments,
            });
          } else {
            setInvoiceDetails(createEmptyDetails());
          }
          if (props.deposit) {
            //Create a subset of payments that match the deposit.
            const tmpDepositPayments = props.allPayments.filter(
              (p) => p.DepositId === props.deposit?.Id
            );
            const tmpPaymentsAlreadyAssigned = tmpDepositPayments.reduce(
              (sum, current) => sum + current.AmountPaid,
              0
            );
            const availableToAssign = Number(
              (
                (props.deposit?.DepositAmountLocal ?? 0).valueOf() -
                tmpPaymentsAlreadyAssigned.valueOf()
              ).toFixed(2)
            );

            if (availableToAssign <= 0) {
              tempErrors.push({
                message:
                  "The selected deposit has alredy been fully allocated.",
                isError: true,
              });
            }

            setDepositDetails({
              sum: tmpPaymentsAlreadyAssigned,
              openAmount: availableToAssign,
              payments: tmpDepositPayments,
            });
          } else {
            setDepositDetails(createEmptyDetails());
          }

          if (!props.deposit && !props.invoice) {
            reset();
          }
          setMatchingErrors(tempErrors);
        } catch (error) {
          console.log("Mapping component, useEffect Error ", error);
        }
      })();
    }, [
      props.invoice,
      props.deposit,
      props.allPayments,
      props.filteredPayments,
      reset,
    ]);

    const cantSave = (): boolean => {
      console.log("cant save", errors);
      return (
        matchingErrors.filter((e) => e.isError).length > 0 ||
        !props.invoice ||
        !props.deposit ||
        !(errors.AmountPaid === null || errors.AmountPaid === undefined) ||
        getValues("AmountPaid") === 0
      );
    };
    return (
      <Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            xs={12}
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Selected Invoice
                  </Typography>
                  {props.invoice ? (
                    <Grid container>
                      <Grid item xs={6}>
                        <label>Invoice Number:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{props.invoice.InvoiceNumber}</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>Issue Date:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {formatDate(
                            props.invoice.IssueDate
                              ? props.invoice.IssueDate.toString()
                              : "",
                            false
                          )}
                        </label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Period Ending:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {formatDate(
                            props.invoice.PeriodEnding
                              ? props.invoice.PeriodEnding.toString()
                              : "",
                            false
                          )}
                        </label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Invoice Type:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {
                            props.invoiceTypes.find(
                              (bg) => bg.Id === props.invoice?.InvoiceTypeId
                            )?.Value
                          }
                        </label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>Billing Group:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {
                            props.billingGroups.find(
                              (bg) => bg.Id === props.invoice?.GroupId
                            )?.GroupName
                          }
                        </label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Invoice Fee Local/USD:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{`${props.invoice.FeeAmountLocal} / ${props.invoice.FeeAmountUSD}`}</label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Adjustment Local/USD:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{`${props.invoice.FeeAdjustmentLocal} / ${props.invoice.FeeAdjustmentUSD}`}</label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Deposits Amount Assigned (Local):</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{invoiceDetails.sum}</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>Open Amount (Local):</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label
                          className={
                            invoiceDetails.openAmount <= 0 ? classes.error : ""
                          }
                        >
                          {invoiceDetails.openAmount}
                        </label>
                      </Grid>
                    </Grid>
                  ) : (
                    <p>
                      <em>Please select an invoice.</em>
                    </p>
                  )}
                </CardContent>
                {props.invoice && (
                  <CardActions disableSpacing>
                    <IconButton aria-label="Clear selection">
                      <ClearIcon
                        onClick={(e) => props.clearSelectedInvoice()}
                      />
                    </IconButton>
                  </CardActions>
                )}
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Selected Deposit
                  </Typography>
                  {props.deposit ? (
                    <Grid container>
                      <Grid item xs={6}>
                        <label>Bank Account:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {
                            props.bankAccounts.find(
                              (bg) => bg.Id === props.deposit?.BankAccountId
                            )?.Name
                          }
                        </label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Deposit Amount Local/USD:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{`${props.deposit.DepositAmountLocal} / ${props.deposit.DepositAmount}`}</label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Currency:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {
                            props.currencies.find(
                              (bg) => bg.Id === props.deposit?.DepositCurrencyId
                            )?.ISOCode
                          }
                        </label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>Already Assigned(Local):</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{props.deposit ? depositDetails.sum : ""}</label>
                      </Grid>

                      <Grid item xs={6}>
                        <label>Available to Assign(Local):</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label
                          className={
                            props.deposit
                              ? depositDetails.openAmount <= 0
                                ? classes.error
                                : ""
                              : ""
                          }
                        >
                          {props.deposit ? depositDetails.openAmount : ""}
                        </label>
                      </Grid>


                      <Grid item xs={6}>
                        <label>Is Processed:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <Checkbox checked={props.deposit.isProcessed} />
                      </Grid>

                      <Grid item xs={6}>
                        <label>Notes:</label>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {truncateLongText(props.deposit.Notes ?? "", 50)}
                        </label>
                      </Grid>
                    </Grid>
                  ) : (
                    <p>
                      <em>Please select a deposit.</em>
                    </p>
                  )}
                </CardContent>
                {props.deposit && (
                  <CardActions disableSpacing>
                    <IconButton aria-label="Clear selection">
                      <ClearIcon
                        onClick={(e) => props.clearSelectedDeposit()}
                      />
                    </IconButton>
                  </CardActions>
                )}
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        fullWidth={true}
                        margin="none"
                        autoComplete={"off"}
                        label="Amount Paid"
                        disabled={!props.invoice && !props.deposit}
                        defaultValue={"0"}
                        {...register("AmountPaid", {
                          required: "Amount is required",
                          onChange: (e) => validateAmountPaid(e.target.value),
                          valueAsNumber: true,
                        })}
                      />
                      {errors.AmountPaid && (
                        <p className={classes.error}>
                          <em>{errors.AmountPaid.message}</em>
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        margin="none"
                        fullWidth={true}
                        inputProps={{ maxLength: 255 }}
                        disabled={!props.invoice && !props.deposit}
                        autoComplete={"off"}
                        label="Notes"
                        defaultValue={""}
                        {...register("Notes")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <ul className={classes.errorList}>{errorItems}</ul>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      alignItems="right"
                      justifyContent="right"
                      className={classes.buttonsMargin}
                    >
                      <Box
                        display="flex"
                        justifyContent="right"
                        alignItems="right"
                      >
                        <StyledButton
                          disabled={!props.deposit && !props.invoice}
                          label="Clear Selections"
                          onClick={() => {
                            props.clearSelectedDeposit();
                            props.clearSelectedInvoice();
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.buttonsMargin}>
                      <Box
                        display="flex"
                        justifyContent="left"
                        alignItems="left"
                      >
                        <div className={classes.rightButton}>
                          <StyledButton
                            label="Link Deposit"
                            isSubmit={true}
                            disabled={cantSave()}
                            onClick={() => {}}
                          />
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Fragment>
    );
  };

export default InvoiceDepositMapping;
