import React from "react";
import { IMReportingStore } from "./stores";
import { ApiService } from "./services/ApiService";
import { IMReportingUserStore } from "./stores/IMReportingUserStore";

let webRelativeUrl = "";

// development
if (process.env.NODE_ENV === "production") {
  webRelativeUrl = "../";
}

export interface AppStore {
  apiService: ApiService;
  imReportingStore: IMReportingStore;
  imReportingUserStore: IMReportingUserStore;
}

const apiService = new ApiService(webRelativeUrl, "/api/");

export const defaultAppStore: AppStore = {
  apiService: apiService,
  imReportingStore: new IMReportingStore(apiService),
  imReportingUserStore: new IMReportingUserStore(apiService)  
};

export const AppContext = React.createContext(defaultAppStore);
