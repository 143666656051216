// import { UseInputProps } from "@mui/material";
// import { get } from "react-hook-form";
import { UserInfo } from "../common/UserInfo";
import { UserRole } from "../common/UserRole";
import { ApiService } from "../services/ApiService";


export class IMReportingUserStore {
    private _apiService: ApiService;
  
    constructor(apiService: ApiService) {
      this._apiService = apiService;
    }

    public async getUserInfo(): Promise<UserInfo | undefined> {
      try {
        const response = await fetch("/.auth/me");     
        const payload = await response.json();

        const { clientPrincipal } = payload;
        console.log('Trying to get user roles for user ', clientPrincipal);
        const appRoles = await this.getAllUserRoles();



        // return clientPrincipal;
        const user : UserInfo = {
          identityProvider: clientPrincipal.identityProvider,
          userDetails : clientPrincipal.userDetails,
          userId: clientPrincipal.userId,
          userRoles: clientPrincipal.userRoles,
          applicationRoles: appRoles
        };
        return user;
      } catch (error) {
        console.error("No user profile could be found.");
        return undefined;
      }
    }

    public async getAllUserRoles(): Promise<UserRole[]> {
        const response = await this._apiService.get("user-roles-get", {});
        console.log("response from get user roles", response);
        return response.data;
      }
}